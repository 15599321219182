.portal-news  {
  @include global-component;
  margin-bottom: $spacer * 4;
  padding: 0 $spacer;

  @include media-breakpoint-up(sm) {
    padding: 0 $spacer;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.portal-news__headline {
  @include global-component__headline--portal;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' width='20' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.167.333H3.056a.833.833 0 0 0-.834.834v.277H.833A.833.833 0 0 0 0 2.278v9.444c0 1.074.87 1.945 1.944 1.945h16.39c.92 0 1.666-.747 1.666-1.667V1.167a.833.833 0 0 0-.833-.834ZM1.944 12a.278.278 0 0 1-.277-.278V3.111h.555v8.611a.278.278 0 0 1-.278.278Zm8.195-.556H4.86a.417.417 0 0 1-.417-.416v-.278c0-.23.187-.417.417-.417h5.278c.23 0 .417.187.417.417v.278c0 .23-.187.416-.417.416Zm7.222 0h-5.278a.417.417 0 0 1-.416-.416v-.278c0-.23.186-.417.416-.417h5.278c.23 0 .417.187.417.417v.278c0 .23-.187.416-.417.416ZM10.14 8.111H4.86a.417.417 0 0 1-.417-.417v-.277c0-.23.187-.417.417-.417h5.278c.23 0 .417.186.417.417v.277c0 .23-.187.417-.417.417Zm7.222 0h-5.278a.417.417 0 0 1-.416-.417v-.277c0-.23.186-.417.416-.417h5.278c.23 0 .417.186.417.417v.277c0 .23-.187.417-.417.417Zm0-3.333h-12.5a.417.417 0 0 1-.417-.417V2.972c0-.23.187-.417.417-.417h12.5c.23 0 .417.187.417.417v1.389c0 .23-.187.417-.417.417Z' fill='#{encode_color( map_get($app-colors, 'orange') )}'/%3E%3C/svg%3E");
  background-position: 0 5px;
}

.portal-news__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &::after {
    content: '';
    flex: 1;
    max-width: 100%;
    min-width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 48.5%;
      min-width: 48.5%;
    }
  }
}

.portal-news__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: $spacer * 2 !important;
  max-width: 100%;
  min-width: 100%;
  // transition: transform 0.5s;

  @include media-breakpoint-up(lg) {
    max-width: 48.5%;
    min-width: 48.5%;
  }

  &:hover {
    a.portal-news__item-title {
      color: map_get($app-colors, "blue");
      span {
        text-decoration: underline;
      }
    }
    // transform: scale(1.03);
  }
}

.portal-news__item-inner {
  background: map_get($app-colors, 'neutral--100');
  border-radius: $spacer;
  flex: 1;
  padding: $spacer;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: $spacer * 2;
  }
}

.portal-news__item-eyebrow {
  @include global-component__eyebrow--light;
}

a.portal-news__item-title {
  @include global-card-title;
}

.portal-news__item-date {
  @include global-card-date;
}


.portal-news__member-only-badge {
  @include global-member-only-badge;
}

.portal-news__cta-link-wrapper {
}

a.portal-news__cta-link {
  @include global-component__cta-link;
  color: map_get($app-colors, 'neutral--100');
  margin-top: 0;

  &:hover,
  &:focus {
    color: map_get($app-colors, "neutral--100");
  }
}
