.portal-featured-cta  {
  @include global-component;
  margin-bottom: $spacer * 4;
  padding: 0 $spacer;

  @include media-breakpoint-up(sm) {
    padding: 0 $spacer;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.portal-featured-cta__headline {
  @include global-component__headline--portal;
}

.portal-featured-cta__title {
  color: map_get($app-colors, 'purple');
  font-size: 1.35rem;
  font-weight: 500;
}

.portal-featured-cta__content-wrapper {
  border: 2px solid #0D001F;
  border-radius: $spacer;
  overflow: hidden;
}

.portal-featured-cta__text-wrapper {
  padding: $spacer * 2;
  padding-top: $spacer * 2.5;
}

.portal-featured-cta__image-wrapper {
  height: 100%;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative;

  @include media-breakpoint-up(lg) {
  }
}

.portal-featured-cta__image {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.portal-featured-cta__cta-buttons {
  @include global-component__cta-buttons;
}

// ============================================================================================
// Embedded Component Overrides
.portal-featured-cta .rich-text {
  color: map_get($app-colors, 'purple');
  padding: 0;
}
