.cta-banner {
  @include global-component;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}


.cta-banner__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: ($spacer * 1.875) ($spacer * 1.25);

  @include media-breakpoint-up(lg) {
    padding: ($spacer * 3) ($spacer * 4.5);
  }
}

.cta-banner__headline {
  @include global-component__headline;
}

.cta-banner__cta-buttons {
  @include global-component__cta-buttons;
}

.cta-banner__image-wrapper {
  min-height: 300px;
  // overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    max-width: 40%;
    min-width: 40%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 40%;
    min-width: 40%;
  }
}

.cta-banner__image {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}



// ================================================================================================
// Theme overrides
.t-orange {
  .cta-banner__content {
    color: map_get($app-colors, "purple")
  }
}

.t-blue {
  .cta-banner__content {
    color: map_get($app-colors, "neutral--100");

    .btn {
      border-color: map_get($app-colors, "neutral--100");
      color: map_get($app-colors, "neutral--100");
    }
  }
}

.t-green {
  background-color: map_get($app-colors, "green");
}

.t-green-and-blue {
  position: relative;
  background-color: map_get($app-colors, "green");

  @include media-breakpoint-down(md) {
    gap: 112px;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    height: 77%;
    background-color: #5162f4;

    @include media-breakpoint-down(md) {
      top: unset;
      bottom: 112px;
      width: 211px;
      height: 309px;
    }
  }

  .cta-banner__content {
    color: map_get($app-colors, "neutral--900");
    gap: 22px;

    .cta-banner__headline {
      color: map_get($app-colors, "indigo");
      margin: 0;
    }

    .btn-primary {
      margin: 0;
    }
  }

  .cta-banner__image-wrapper {
    margin-right: ($spacer * 2.6);
    margin-top: ($spacer * 2.7);
    position: relative;
    z-index: 2;

    @include media-breakpoint-down(md) {
      margin: 0 ($spacer * 1.25);
    }
  }
}


// ============================================================================
// Overrides pages with sidebar
body.content-has-sidebar {
  .app-subpage-content {
    .cta-banner {
      @include media-breakpoint-up(lg) {
        flex-direction: column;
      }

      @include media-breakpoint-up(xl) {
        flex-direction: row;
      }
    }

    .cta-banner__image-wrapper {
        @include media-breakpoint-up(md) {
          max-width: 40%;
          min-width: 40%;
        }
        @include media-breakpoint-up(lg) {
          max-width: 100%;
          min-width: 100%;
        }
        @include media-breakpoint-up(xl) {
          max-width: 40%;
          min-width: 40%;
        }
    }

  }

}
