.app-footer {
  background-color: map_get($app-colors, "neutral--200");
  font-size: 0.855rem;
  font-weight: 400;

  @include media-breakpoint-up(md) {
    padding: ($spacer * 2.5) 0;
  }

  .container {
    max-width: 1440px;
  }


  a {
    border-radius: .05rem;
    color: map_get($app-colors, "neutral--850");
    text-decoration: none;

    &:focus {
      box-shadow:
        0 0 0 2px map_get($app-colors, 'neutral--100'),
        0 0 0 4px map_get($app-colors, 'blue');
      outline: 2px solid transparent;
      outline-offset: 6px;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .btn {
    font-weight: 700;
    padding-left: $spacer;
    padding-right: $spacer;
  }

}

.app-footer__inner {
  display: flex;
  flex-direction: column;
  padding: $spacer 0;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 3fr 3.5rem 1fr;
    grid-template-rows: min-content min-content min-content min-content min-content;
  }
}


.app-footer__primary-nav {

  @include media-breakpoint-up(md) {
    padding-bottom: $spacer * 2;
  }

  @include media-breakpoint-up(lg) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  // Manage visibility of mobile vs desktop versions
  &.app-footer__primary-nav--mobile {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &.app-footer__primary-nav--desktop {
      display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

.app-footer__primary-nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 4rem;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 1.5;
  }

  li {
    // display: none;
    margin: 0 0 ($spacer * .5) 0;

    a {
      align-items: center;
      background-size: 9px 9px;
      border-bottom: 1px solid $border;
      display: flex;
      font-weight: 600;
      justify-content: space-between;
      margin-bottom: $spacer * .4;
      padding-bottom: $spacer * .4;
      width: 100%;

      @include media-breakpoint-up(md) {
        display: inline-block;
        font-size: .9rem;
      }
    }

    &:not(:first-child) {
        a {
        @include media-breakpoint-up(md) {
          border-bottom: 0;
          display: inline;
          font-size: .8rem;
          font-weight: 400;
          margin-bottom: 0;
          padding: 0;
        }
      }
    }

  }
}


.app-footer__cta {
  padding-bottom: $spacer * 1.5;

  @include media-breakpoint-up(md) {
    border-top: 1px solid map_get($app-colors, "neutral--400");
    padding-top: $spacer;
  }

  @include media-breakpoint-up(lg) {
    border-top: 0;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 6;
    padding-top: 0;
  }
}

.app-footer__logo-link {
  display: block;
  max-width: 250px;
  min-width: 170px;
  width: 50%;
  margin-bottom: $spacer;
}


.app-footer__contact {
  padding-bottom: $spacer * 1.5;

  @include media-breakpoint-up(md) {
    border-top: 1px solid map_get($app-colors, "neutral--400");
    padding-top: $spacer;
  }

  @include media-breakpoint-up(lg) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  > .row > .col {
    padding-top: $spacer * 1.25;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }

  }

  p {
    margin-bottom: $spacer * .25;
  }
}

.app-footer__social-links-list {
  list-style: none;
  padding: 0;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  li {
    margin-right: 10px;

    a {
      display: block;

      &:focus {
        box-shadow:
          0 0 0 2px map_get($app-colors, 'neutral--100'),
          0 0 0 4px map_get($app-colors, 'blue');
        outline: 2px solid transparent;
        outline-offset: 6px;
        text-decoration: none;

        svg {
          path {
            fill: map_get($app-colors, 'blue');
          }
        }
      }

      &:hover {
        text-decoration: underline;
      }

      svg {
        width: 28px;
        height: 28px;
      }

    }
  }
}

.app-footer__sponsors {
  padding-bottom: $spacer * 3.5;

  @include media-breakpoint-up(md) {
    border-top: 1px solid map_get($app-colors, "neutral--400");
    padding-top: $spacer;
  }

  @include media-breakpoint-up(lg) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    padding-bottom: $spacer * 1.5;
  }

}

.app-footer__utility {
  border-top: 1px solid map_get($app-colors, "neutral--400");
  padding-top: $spacer;

  @include media-breakpoint-up(lg) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 6;
  }
}

.app-footer__utility-nav-list {
  font-size: .775rem;
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 1.25rem;
  }
}

.app-footer__copyright {
  font-size: .775rem;

  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}


// ============================================================================================
// Embedded Component Overrides

.app-footer .logo-zone.is-inline {
  padding: 0;

  .logo-zone__headline {
    @include media-breakpoint-up(lg) {
      font-size: inherit;
      padding-top: 0;
      text-align: left;
    }
  }

  .logo-zone__list {
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      margin: 0;
    }
  }

  .logo-zone__list-item {

    img {
      filter: grayscale(100%);

      @include media-breakpoint-up(lg) {
        margin: 0 1rem;
      }

      &.is-vertical {
        height: 55px;
      }

      &.is-horizontal {
        height: 45px;
      }

      &.is-horizontal--extreme {
        height: 25px;
      }

    }

  }

}
