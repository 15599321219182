// ============================================================================================
// Bootstrap Refinements

.invalid-feedback {
  line-height: 1.5;
  margin-bottom: $spacer * .75;
}

.form-label,
legend {
  float: none;
  font-weight: 700;
  width: initial;
}

.form-label,
.form-check-label,
legend {

  &.is-required {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='asterisk' class='svg-inline--fa fa-asterisk fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{encode_color( map_get($app-colors, 'red--error') )}' d='M478.21 334.093 336 256l142.21-78.093c11.795-6.477 15.961-21.384 9.232-33.037l-19.48-33.741c-6.728-11.653-21.72-15.499-33.227-8.523L296 186.718l3.475-162.204C299.763 11.061 288.937 0 275.48 0h-38.96c-13.456 0-24.283 11.061-23.994 24.514L216 186.718 77.265 102.607c-11.506-6.976-26.499-3.13-33.227 8.523l-19.48 33.741c-6.728 11.653-2.562 26.56 9.233 33.037L176 256 33.79 334.093c-11.795 6.477-15.961 21.384-9.232 33.037l19.48 33.741c6.728 11.653 21.721 15.499 33.227 8.523L216 325.282l-3.475 162.204C212.237 500.939 223.064 512 236.52 512h38.961c13.456 0 24.283-11.061 23.995-24.514L296 325.282l138.735 84.111c11.506 6.976 26.499 3.13 33.227-8.523l19.48-33.741c6.728-11.653 2.563-26.559-9.232-33.036z'/%3E%3C/svg%3E");
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: center top;
      content: '';
      display: inline-block;
      padding-right: 14px;
      width: 8px;
      height: .9em;
    }
  }
}

legend + .invalid-feedback {
  margin-top: -$spacer * .5;
}

legend + .form-text,
.form-label + .form-text {
  color: map_get($app-colors, "neutral--700");
  line-height: 1.5;
  margin-bottom: $spacer * .5;
  margin-top: -$spacer * .5;
}


.form-check {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding-left: $spacer * 1.5;
  position: relative;

  // This exists for when there needs to be additional info added besides the label (i.e. Portal Subscriptions page)
  > div {
    display: inline;
    line-height: 1;

    p {
      margin-bottom: 0;
      margin-top: $spacer * .25;
      line-height: 1.5;
    }
  }

  .form-check-input {
    border-color: map_get($app-colors, "neutral--900");
    float: none;
    position: absolute;
    left: 0;
    margin-left: 0;
    padding: 0;

    &[type=checkbox] {
      background-color: #ffffff;

      &:checked {
        background-color: map_get($app-colors, "blue");
      }
    }

    &[type=radio] {
      background-color: #ffffff;

      &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='#{encode_color( map_get($app-colors, 'blue') )}'/%3e%3c/svg%3e")
      }
    }
  }

  .form-check-label {
    line-height: 1.5;
    padding-right: $spacer;
  }

  &.form-check--light {
    .form-check-label {
      font-weight: 400 !important;
    }
  }

  &.form-check--small {
    .form-check-label {
      font-size: 0.9rem;
      font-weight: 400;
      margin-top: -.15rem;

      // Adjust size/placement of external link icon if present
      .external-link-icon {
        background-size: 10px;
        height: 10px;
        margin-left: 0.3rem;
        width: 10px;
      }
    }
  }
}

.form-text {
  line-height: 1.3;
}

/*! purgecss ignore */
input[readonly="readonly"] {
  border: 0;
  padding-left: 0;
}


// ============================================================================================
// Additional Form Styles

.form-instructions {
  font-style: italic;

  .form-instructions__required-marker {
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='asterisk' class='svg-inline--fa fa-asterisk fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{encode_color( map_get($app-colors, 'red--error') )}' d='M478.21 334.093 336 256l142.21-78.093c11.795-6.477 15.961-21.384 9.232-33.037l-19.48-33.741c-6.728-11.653-21.72-15.499-33.227-8.523L296 186.718l3.475-162.204C299.763 11.061 288.937 0 275.48 0h-38.96c-13.456 0-24.283 11.061-23.994 24.514L216 186.718 77.265 102.607c-11.506-6.976-26.499-3.13-33.227 8.523l-19.48 33.741c-6.728 11.653-2.562 26.56 9.233 33.037L176 256 33.79 334.093c-11.795 6.477-15.961 21.384-9.232 33.037l19.48 33.741c6.728 11.653 21.721 15.499 33.227 8.523L216 325.282l-3.475 162.204C212.237 500.939 223.064 512 236.52 512h38.961c13.456 0 24.283-11.061 23.995-24.514L296 325.282l138.735 84.111c11.506 6.976 26.499 3.13 33.227-8.523l19.48-33.741c6.728-11.653 2.563-26.559-9.232-33.036z'/%3E%3C/svg%3E");
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    height: $spacer;
    width: $spacer;
  }
}
