.partner-profile {
  @include global-component;
}

.partner-profile__eyebrow {
  @include global-component__eyebrow;
  line-height: 1.5;
  @include media-breakpoint-up(md) {
    line-height: 1.9;
  }

  & > *:after {
    bottom: -12px;

    @include media-breakpoint-up(md) {
      bottom: -6px;
    }
  }
}

.partner-profile__headline {
  @include global-component__headline;
  max-width: 900px;
}

.partner-profile__description {
  @include global-component__description;
  max-width: 900px;
}

.partner-profile__cards {
  list-style-type: none;
  margin: 0;
  padding: ($spacer * 2) 0 0 0;
  display: grid;
  grid-template-columns: 100fr;
  column-gap: $spacer;
  row-gap: $spacer;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 50fr 50fr;
  }
}

.partner-profile__card {
  position: relative;
  display: flex;
  flex-direction: column;
}

.partner-profile__card-inner {
  background-color: #f9f9ff;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacer * 2;

  @include media-breakpoint-up(sm) {
    align-items: center;
    flex-direction: row;
  }

  @include media-breakpoint-up(lg) {
    align-items: initial;
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;

    a {
      color: map_get($app-colors, "blue");
      text-decoration: underline;
    }
  }
}

.partner-profile__card-text-wrapper {
  flex: 1;
  margin-bottom: $spacer;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer;
  }
}

.partner-profile__card-title {
  margin-bottom: 0;

  a {
    @include global-card-title;
    margin-bottom: 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.partner-profile__card-description {
  @include global-component__description;
  // display: none;
  padding-top: $spacer * 0.5;
}

.partner-profile__card-image-wrapper {
  width: 150px;
  max-height: 85px;
  position: relative;
  min-height: 55px;
  display: flex;

  @include media-breakpoint-up(md) {
    min-height: 75px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 55px;
    width: 33%;
  }

  img {
    object-fit: contain;
    object-position: left;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    @include media-breakpoint-up(sm) {
      object-position: center;
    }

    @include media-breakpoint-up(lg) {
      object-position: left;
    }
  }
}

.partner-profile__link {
  flex-grow: 1;

  .external-link-icon {
    display: none;
  }
}

// ================================================================================================
// Overrides for when only one profile
.partner-profile__cards.is-single {
  @include media-breakpoint-up(lg) {
    grid-template-columns: 100fr;
  }
  .partner-profile__card-inner {
    @include media-breakpoint-up(lg) {
      align-items: center;
      flex-direction: row;
    }
  }
  .partner-profile__card-text-wrapper {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .partner-profile__card-image-wrapper {
    @include media-breakpoint-up(lg) {
      min-height: 65px;
      width: 30%;
    }

    img {
      @include media-breakpoint-up(lg) {
        object-position: center;
      }
    }
  }
}

// ================================================================================================
// Overrides for 2 column
.app-main__content--2col {
  .partner-profile__cards:not(.is-single) {
    @include media-breakpoint-up(md) {
      grid-template-columns: 100fr;
    }
  }
}

// ============================================================================
// Overrides pages with sidebar
body.content-has-sidebar {
  .partner-profile__cards:not(.is-single) {
    @include media-breakpoint-up(md) {
      grid-template-columns: 100fr;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 50fr 50fr;
    }
  }

  .partner-profile__card-image-wrapper {
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
    @include media-breakpoint-up(xl) {
      width: 55%;
    }
  }
}

// Centered variant for Complex Event Layout
.partner-profile--centered {
  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;
  }

  .partner-profile__cards.is-single {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .partner-profile__eyebrow {
    text-align: center;
    margin-bottom: 0;
  }

  .partner-profile__card-inner {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      padding-left: $spacer * 1.5;
      padding-right: $spacer * 1.5;
    }
  }

  .partner-profile__card-text-wrapper {
    margin-bottom: 0;
  }

  .partner-profile__cards {
    @include media-breakpoint-up(lg) {
      gap: 2.7rem;
    }
  }

  .partner-profile__card {
    aspect-ratio: unset;
    height: 135px;

    @include media-breakpoint-up(lg) {
      aspect-ratio: 3.12;
      height: 157px;
    }
  }
}
