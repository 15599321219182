// Component
@mixin global-component {
  flex: 1;
  padding: ($spacer * 2) $spacer;

  @include media-breakpoint-up(lg) {
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
  }
}

@mixin global-component__headline {
  color: map_get($app-colors, "neutral--900");
  font-size: 1.55rem;

  a {
    color: map_get($app-colors, "neutral--850");
    display: block;
  }
}

@mixin global-component__headline--portal {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 20px;
  color: map_get($app-colors, "orange");
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.25rem;
  line-height: 24px;
  margin-bottom: $spacer * 1.5;
  padding-left: $spacer * 2;
  position: relative;
  text-transform: uppercase;

  svg {
    max-width: 20px;
    min-width: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    path {
      fill: map_get($app-colors, "orange");
    }
  }
}

@mixin global-component__description {
  line-height: 1.85;
  max-width: 900px;
  padding-bottom: $spacer;

  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
    line-height: 1.75;
  }
}

@mixin global-component__eyebrow {
  margin-bottom: $spacer * 2;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 3;
  }

  > * {
    color: map_get($app-colors, "purple");
    display: inline-block;
    font-size: 0.95rem;
    font-weight: 700;
    letter-spacing: 0.15rem;
    margin-bottom: 0;
    position: relative;
    text-transform: uppercase;

    &:after {
      background-color: map_get($app-colors, "green");
      content: "";
      height: 5px;
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
    }
  }
}

@mixin global-component__eyebrow--light {
  margin-bottom: $spacer * 0.25;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 0.5;
  }

  > * {
    color: map_get($app-colors, "neutral--700");
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 0;
    text-transform: uppercase;

    &:after {
      display: none;
    }
  }
}

@mixin global-component__cta-buttons {
  .btn {
    margin-top: $spacer * 1.5;
    width: 100%;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer;
      margin-top: $spacer;
      width: auto;
    }
  }
}

@mixin global-component__cta-buttons--top {
  .btn {
    margin-top: 0;
    margin-bottom: $spacer * 2;
    width: 100%;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer;
      width: auto;
    }
  }
}

@mixin global-component__cta-link {
  color: map_get($app-colors, "neutral--850");
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.6;
  margin-top: $spacer * 1.5;
  padding-left: 2rem;
  position: relative;
  text-decoration: none !important;

  &:before {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{encode_color( map_get($app-colors, 'blue') )}' d='M0 .5h23v23H0z'/%3E%3Cpath d='M10.677 7.237l.545-.56a.576.576 0 01.832 0l4.773 4.895c.23.237.23.62 0 .854l-4.773 4.896a.576.576 0 01-.832 0l-.545-.559a.616.616 0 01.01-.864l2.958-2.891H6.59A.596.596 0 016 12.403v-.806c0-.335.263-.605.59-.605h7.055l-2.958-2.891a.612.612 0 01-.01-.864z' fill='%23fff'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: $spacer * 0.5;
    position: absolute;
    left: 0;
  }

  &:after {
    background-color: map_get($app-colors, "blue");
    bottom: -12px;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;
    width: 100%;
  }

  &:hover,
  &:focus {
    outline: none;
    &:after {
      transform: scaleX(1);
    }
    color: map_get($app-colors, "neutral--850");
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  .external-link-icon {
    margin-left: 0.25rem !important;
  }
}

@mixin global-member-only-badge {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='lock' class='svg-inline--fa fa-lock fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{encode_color( #865B11 )}' d='M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z'%3E%3C/path%3E%3C/svg%3E");
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  border-radius: 20px;
  border: 1px solid #865b11;
  color: map_get($app-colors, "neutral--900");
  display: inline-block;
  font-size: 0.875rem;
  padding: 3px 1rem 3px 2rem;

  &.is-unlocked {
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='lock-open' class='svg-inline--fa fa-lock-open fa-w-18' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='#{encode_color( #865B11 )}' d='M423.5 0C339.5.3 272 69.5 272 153.5V224H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48h-48v-71.1c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v80c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-80C576 68 507.5-.3 423.5 0z'/%3E%3C/svg%3E");
  }
}

// Card
// Inline link + icon technique from https://www.jayfreestone.com//writing/wrapping-and-inline-pseudo-elements/
@mixin global-card-title {
  color: map_get($app-colors, "neutral--850");
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: $spacer;
  padding-right: 11px;
  text-decoration: none;

  // &:after {
  //   background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' fill='#{encode_color( map_get($app-colors, 'blue') )}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.677.737.545-.56a.576.576 0 0 1 .832 0l4.773 4.895c.23.237.23.62 0 .854l-4.773 4.896a.576.576 0 0 1-.832 0l-.545-.559a.616.616 0 0 1 .01-.864l2.958-2.891H.59A.596.596 0 0 1 0 5.903v-.806c0-.335.263-.605.59-.605h7.055L4.687 1.601a.612.612 0 0 1-.01-.864Z' /%3E%3C/svg%3E");
  //   background-position: right center;
  //   background-repeat: no-repeat;
  //   background-size: 11px;
  //   content: "\00A0\00A0";
  //   height: 1.5rem;
  //   // width: 11px;
  //   display: inline;
  //   // vertical-align: middle;
  //   // margin-top: -0.2em;
  //   // margin-left: 0.2em;
  //   // margin-right: calc(11px * -1);
  // }

  &:hover,
  &:focus {
    span {
      color: map_get($app-colors, "blue");
      text-decoration: underline;
    }
  }
}

@mixin global-card-featured-label {
  background-color: map-get($app-colors, "blue");
  bottom: 0;
  color: map-get($app-colors, "neutral--100");
  display: inline-block;
  font-size: 0.85rem;
  left: 0;
  margin-bottom: 0;
  padding: 2px $spacer;
  position: absolute;
  z-index: 1;
}

@mixin global-card-description {
  color: map_get($app-colors, "neutral--850");
  font-size: 1rem;
  line-height: 1.9;
}

@mixin global-card-date {
  text-transform: uppercase;
  font-size: 0.875rem;
  color: map_get($app-colors, "neutral--700");
}
