
.autocomplete__wrapper {
  position: relative;
}

.autocomplete__wrapper--has-error {
  .autocomplete__input {
    @extend .is-invalid;
  }
}

.autocomplete__hint,
.autocomplete__input {
  @extend .form-control;
}

.autocomplete__input {
}

.autocomplete__hint {
  color: #b1b4b6;
  position: absolute;
}

.autocomplete__input--default {
}
.autocomplete__input--focused {

}

.autocomplete__input--show-all-values {
  padding: 5px 34px 5px 5px; /* Space for arrow. Other padding should match .autocomplete__input--default. */
  cursor: pointer;
}

.autocomplete__dropdown-arrow-down{
  z-index: -1;
  display: inline-block;
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  top: 10px;
}

.autocomplete__menu {
  background-color: #fff;
  border: 0;
  color: #0B0C0C;
  margin: 0;
  max-height: 342px;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  width: calc(100% - 4px);
}

.autocomplete__menu--visible {
  display: block;
}

.autocomplete__menu--hidden {
  display: none;
}

.autocomplete__menu--overlay {
  box-shadow: rgb(0 0 0 / 75%) 2px 5px 9px;
  left: 0;
  position: absolute;
  top: 96%;
  z-index: 100;
}

.autocomplete__menu--inline {
  position: relative;
}

.autocomplete__option {
  border-bottom: solid #b1b4b6;
  border-width: 1px 0;
  cursor: pointer;
  display: block;
  position: relative;

  &:hover {
    background-color: map_get($app-colors, "blue--pale");
    color: map_get($app-colors, "neutral--900");
  }

  &:first-of-type {
    border-top-width: 0;
  }

  &:last-of-type {
    border-bottom-width: 0;
  }
}

// When add-new data attribute exists and is true, style the last option (since it will be the add new trigger)
[data-allow-add-new='true' i] {
  .autocomplete__option {
    &:last-child {
      background-color: map_get($app-colors, "neutral--800");
      color: map_get($app-colors, "neutral--100");
      padding-bottom: $spacer * .5;
      padding-top: $spacer * .5;

      &:hover {
      }

      &.autocomplete__option--focused,
      &:hover {
        background-color: map_get($app-colors, "blue--dark");
        color: map_get($app-colors, "neutral--100");
      }

    }
  }
}

.autocomplete__option > * {
  pointer-events: none;
}

.autocomplete-option__content {
  font-size: 0.8rem;
}
.autocomplete-option__company {
  font-size: 0.9rem;
  font-weight: 500;
}
.autocomplete-option__address {
  font-style: italic;
}
.autocomplete-option__city {
  font-style: italic;
}


.autocomplete__option--odd {
  background-color: #FAFAFA;
}

.autocomplete__option--focused,
.autocomplete__option:hover {
  background-color: map_get($app-colors, "blue");
  border-color: map_get($app-colors, "blue");
  color: map_get($app-colors, "neutral--100");;
  outline: none;
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .autocomplete__menu {
    border-color: FieldText;
  }

  .autocomplete__option {
    background-color: Field;
    color: FieldText;
  }

  .autocomplete__option--focused,
  .autocomplete__option:hover {
    forced-color-adjust: none; /* prevent backplate from obscuring text */
    background-color: Highlight;
    border-color: Highlight;
    color: HighlightText;

    /* Prefer SelectedItem / SelectedItemText in browsers that support it */
    background-color: SelectedItem;
    border-color: SelectedItem;
    color: SelectedItemText;
    outline-color: SelectedItemText;
  }
}

.autocomplete__option--no-results {
  background-color: #FAFAFA;
  color: #646b6f;
  cursor: not-allowed;
}

.autocomplete__hint,
.autocomplete__input,
.autocomplete__option {
  font-size: 1rem;
  line-height: 1.25;
}

.autocomplete__hint,
.autocomplete__option {
  padding: $spacer * .25;
  padding-left: $spacer * .75;
}

@media (min-width: 641px) {
  .autocomplete__hint,
  .autocomplete__input,
  .autocomplete__option {
    font-size: 1rem;
    line-height: 1.31579;
  }
}


.autocomplete__wrapper {
  /* When the Account Create form has errors, we populate placeholder of this input
   * with the company value to prevent triggering the autocomplete options
   */
  input#ExistingCompany::placeholder {
      color: #212529;
  }
}
