.video {
  @include global-component;
}


.video__transcript-toggle {
  margin: ($spacer * 1.25) 0;
  text-align: right;

  button {
    color: map_get($app-colors, "blue");
    background-color: map_get($app-colors, "neutral--100");
    background-image: url("../images/transcript.svg");
    background-position: 14% 50%;
    background-size: auto;
    background-repeat: no-repeat;
    padding: 1rem 1.375rem 1rem 3.5rem;

    &:hover,
    &:focus {
      background-color: rgba(map_get($app-colors, "blue--pale"), 0.5);
    }
  }
}

.video__transcript-content {
  height: 300px;
  overflow-y: auto;
  display: none;
  padding: $spacer;
  border-top: 2px solid map_get($app-colors, "blue");

  @include media-breakpoint-up(lg) {
    padding: $spacer * 1.25;
  }
}


// ============================================================================
// Overrides for when in 1 column layout
.app-main__content--1col {
  .video {
    @include media-breakpoint-up(lg) {
      width: 80%;
      margin: 0 auto;
    }
  }
}



// ============================================================================
// Embedded Component Overrides
.video {
  .video__transcript-content {
    .rich-text {
      padding: 0;
    }
  }
}
