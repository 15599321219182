.product-details {
  @include global-component;
  background-color: map_get($app-colors, 'neutral--200');
  position: relative;

  margin: 2rem 0 !important;

  &::before {
    background: linear-gradient(
      90deg,
      map-get($app-colors, "orange") 56.99%,
      map-get($app-colors, "gold") 147.71%
    );
    content:'';
    display: block;
    height: $spacer * .75;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.product-details__headline {
  @include global-component__headline;
  border-bottom: 1px solid map_get($app-colors, 'neutral--400');
  font-size: 1.75rem;
}

.product-details__price {
  margin-bottom: $spacer * .75;
}

.product-details__price-amount {
  font-size: 1.4rem;
  font-weight: 700;
}
.product-details__price-description {
  color: map_get($app-colors, 'neutral--800');
  font-size: 1rem;
  font-weight: 400;
}

.product-details__price.product-details__price--members {
  margin-top: -1rem;

  .product-details__price-amount {
    color: map_get($app-colors, 'purple');
    font-size: 1rem;
  }
  .product-details__price-description {
    color: map_get($app-colors, 'purple');
  }
}


.product-details__description {
  padding-top: $spacer * 1.5;
}

.product-details__accordion {
  padding: ($spacer * 2) 0;
}

.product-details__accordion-title {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{encode_color( map_get($app-colors, 'blue') )}' d='M207.029 381.476 12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0 9px;
  padding: 0 0 0 ($spacer * 1.2);
  background-size: 10px;
  color: map_get($app-colors, 'blue');
  background-color: transparent;

  &[aria-expanded=true] {
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-up' class='svg-inline--fa fa-chevron-up fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{encode_color( map_get($app-colors, 'blue') )}' d='m240.971 130.524 194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'/%3E%3C/svg%3E");
  }

}

.product-details__accordion-content {
  ul {
    padding-left: $spacer * 1.5;
    li {
      list-style-image: url("data:image/svg+xml,%3Csvg width='16' height='16' aria-hidden='true' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{encode_color( map_get($app-colors, 'green--dark') )}' d='M435.848 83.466 172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
      margin-bottom: $spacer;
      padding-left: $spacer * .25;
    }
  }
}


.product-details__cta-buttons {
  @include global-component__cta-buttons;
  border-top: 1px solid map_get($app-colors, 'neutral--400');
  padding-top: $spacer * 2;

  p {
    color: map_get($app-colors, 'neutral--800');
    font-size: 0.9rem;
    margin-bottom: 0;
  }
}
