.portal-roster-activity-bar {
  @include global-component;
  margin-bottom: $spacer * 4;
  padding-top: 0;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  &.is-on-internal-page {
    margin: 0 0 ($spacer * 2) 0;
    padding: 0;
  }
}

.portal-roster-activity-bar__headline {
  @include global-component__headline--portal;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' width='20' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.078 9.666c.081-.441.081-.891 0-1.332l.806-.465a.226.226 0 0 0 .104-.266A4.69 4.69 0 0 0 18.95 5.81a.228.228 0 0 0-.281-.043l-.806.465a3.668 3.668 0 0 0-1.154-.665v-.932c0-.106-.075-.2-.178-.221a4.715 4.715 0 0 0-2.068 0 .228.228 0 0 0-.179.221v.932c-.421.15-.812.375-1.153.665l-.806-.465a.229.229 0 0 0-.281.043 4.69 4.69 0 0 0-1.038 1.794.23.23 0 0 0 .103.266l.807.465c-.082.441-.082.891 0 1.332l-.807.465a.226.226 0 0 0-.103.266c.21.675.569 1.284 1.038 1.794a.228.228 0 0 0 .281.043l.806-.465c.34.29.732.515 1.153.665v.932c0 .106.075.2.178.222.697.156 1.407.15 2.07 0a.227.227 0 0 0 .177-.222v-.932c.422-.15.813-.375 1.154-.665l.806.465c.09.053.21.035.281-.043a4.69 4.69 0 0 0 1.038-1.794.23.23 0 0 0-.104-.266l-.806-.465Zm-3.578.85A1.517 1.517 0 0 1 13.984 9c0-.834.682-1.516 1.516-1.516s1.516.682 1.516 1.516-.678 1.516-1.516 1.516ZM3 6c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2Zm7 1c.06 0 .116-.016.175-.019a5.76 5.76 0 0 1 1.134-1.85 1.231 1.231 0 0 1 1.516-.228l.247.144c.025-.016.05-.028.075-.044a3.46 3.46 0 0 0 .35-1.5c0-1.934-1.566-3.5-3.5-3.5A3.493 3.493 0 0 0 6.5 3.5C6.5 5.434 8.066 7 10 7Zm3.287 6.078c-.071-.037-.143-.081-.212-.122-.256.15-.478.306-.86.306-.34 0-.668-.143-.902-.393a5.699 5.699 0 0 1-1.257-2.175c-.334-1.078.778-1.553.807-1.572a3.195 3.195 0 0 1 0-.244l-.247-.144a1.274 1.274 0 0 1-.307-.253c-.103.007-.203.02-.306.02a4.933 4.933 0 0 1-2.14-.5h-.26A3.602 3.602 0 0 0 4 11.6v.9A1.5 1.5 0 0 0 5.5 14h7.981a1.203 1.203 0 0 1-.194-.634v-.288ZM5.41 7.581A1.994 1.994 0 0 0 4 7H2C.897 7 0 7.897 0 9v1c0 .553.447 1 1 1h2.06a4.583 4.583 0 0 1 2.35-3.419Z' fill='#{encode_color( map_get($app-colors, 'orange') )}'/%3E%3C/svg%3E");
  background-position: 0 4px;
}

.portal-roster-activity-bar__inner {
  border-radius: 1rem;
  background-color: map-get($app-colors, 'purple');
  color: map-get($app-colors, 'neutral--100');
  padding: $spacer * 1.5;

  @include media-breakpoint-up(lg) {
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;
  }

  p {
    margin-bottom: 0;

    a {
      color: map-get($app-colors, 'neutral--100');
      font-size: 0.9em;
    }
  }
}


.portal-roster-activity-bar__column--items {
  padding-bottom: $spacer * 1.5;

  @include media-breakpoint-up(md) {
    padding-bottom: $spacer;
    padding-top: $spacer;
  }
}

.portal-roster-activity-bar__column--cta {

  @include media-breakpoint-up(md) {
    border-left: 1px solid rgba(map-get($app-colors, 'neutral--100'), .5);
    border-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: $spacer 0 $spacer ($spacer * 3);
  }
}


.portal-roster-activity-bar__item {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  margin-bottom: $spacer;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: $spacer * 2.5;
  }
}

// Progress bar adjustment for unlimited roster seats
.portal-roster-activity-bar__item.is-unlimited {
  .portal-roster-activity-bar__item-progess-bar {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='%23FFF' d='M64 448c-8.188 0-16.38-3.125-22.62-9.375-12.5-12.5-12.5-32.75 0-45.25L178.8 256 41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z'/%3E%3C/svg%3E");
      background-position: 100% 50%;
      background-repeat: no-repeat;
      background-size: 7px;
      content: '';
      display: block;
      height: 14px;
      position: absolute;
      right: -2px;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
    }
  }
}

.portal-roster-activity-bar__item-heading {
  font-weight: 600;
  min-width: 100%;

  @include media-breakpoint-up(xxl) {
    max-width: initial;
    min-width: initial;
    order: 0;
  }
}

.portal-roster-activity-bar__item-description {
  flex: 1;
  font-size: 0.8rem;
  min-width: 100%;
  text-align: right;

  @include media-breakpoint-up(xxl) {
    max-width: initial;
    min-width: initial;
    order: 1;
  }

  span {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.75 4.583v1.834h7.5V4.583c0-1.01.673-1.833 1.5-1.833h.75C13.5 1.232 12.492 0 11.25 0h-7.5C2.508 0 1.5 1.232 1.5 2.75h.75c.827 0 1.5.822 1.5 1.833Zm9.75-.916h-.75c-.415 0-.75.41-.75.916v2.75H3v-2.75c0-.507-.335-.916-.75-.916H1.5C.673 3.667 0 4.489 0 5.5c0 .676.305 1.26.75 1.578v3.464c0 .252.169.458.375.458h1.5c.206 0 .375-.206.375-.458v-.459h9v.459c0 .252.169.458.375.458h1.5c.206 0 .375-.206.375-.458V7.078c.445-.318.75-.902.75-1.578 0-1.011-.673-1.833-1.5-1.833Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
    background-position: 0 4px;
    background-repeat: no-repeat;
    background-size: 15px;
    padding-left: 24px;
  }
}

.portal-roster-activity-bar__item-progess-bar {
  flex: 1;
  min-width: 100%;
  margin: ($spacer * .25) 0;
  max-width: 100%;
  position: relative;

  @include media-breakpoint-up(xxl) {
    order: 2;
  }


  &:before {
    background-color: map-get($app-colors, 'neutral--100');
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  > div {
    background-color: map-get($app-colors, 'green');
    height: 6px;
    position: relative;
    z-index: 1;
  }
}


.portal-roster-activity-bar__cta {
  font-size: 0.95rem;
  border-top: 1px solid rgba(map-get($app-colors, 'neutral--100'), .5);
  margin-top: $spacer;
  padding-top: $spacer;

  @include media-breakpoint-up(md) {
    border-top: 0;
    margin: 0;
    padding: 0;
  }

  a {
    @include media-breakpoint-up(md) {
      display: block;
      margin-top: $spacer * .5;
    }
  }
}
