.headshot {
  @include global-component;
}

.headshot__eyebrow {
  @include global-component__eyebrow;
}

.headshot__list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 -$spacer;
  padding: ($spacer * 2) 0 0 0;
}

.headshot__list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50%;
  max-width: 50%;
  padding: 0 $spacer ($spacer * 3) $spacer;
}

.headshot__image-wrapper {
  margin-bottom: $spacer;
  padding-bottom: 112%;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}

.headshot__name-wrapper {
  &::after {
    content: "";
    display: block;
    height: 6px;
    background-color: map_get($app-colors, "orange");
    width: 100px;
    margin: ($spacer * .75) 0;
  }
}

.headshot__name {
  color: map_get($app-colors, "neutral--850");
  display: inline-block;
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;
  padding-right: 11px;
}

a.headshot__name {
  @include global-card-title;
  margin-bottom: 0;
  text-decoration: none;
}

.headshot__title {
  color: map-get($app-colors, "purple");
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: $spacer * 0.75;
}

.headshot__organization {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.headshot__contact-info {
  line-height: 1.7;
  padding-top: $spacer * 0.75;
}

.headshot__phone {
  font-size: 0.85rem;
  margin-bottom: $spacer * 0.25;
}

.headshot__email {
  font-size: 0.85rem;
  margin-bottom: $spacer * 0.25;

  a {
    text-decoration: none;
  }
}

.headshot__social {
  font-size: 0.85rem;
  margin-bottom: $spacer * 0.25;

  a {
    text-decoration: none;
  }
}

.headshot__cta-link-wrapper {
  border-top: 1px solid map-get($app-colors, "neutral--400");
  margin-top: $spacer;
}

a.headshot__cta-link {
  @include global-component__cta-link;
}

// Size overrides
.headshot.is-small-grid {
  .headshot__list-item {

    @media screen and (min-width: 680px) {
      min-width: 33.333333%;
      max-width: 33.333333%;
    }

    @include media-breakpoint-up(lg) {
      min-width: 25%;
      max-width: 25%;
    }

    .rich-text {
      display: none;
    }
  }
}

.headshot.is-large-grid {
  .headshot__list-item {

    // @include media-breakpoint-up(lg) {
    @media screen and (min-width: 680px) {
      min-width: 33.333333%;
      max-width: 33.333333%;
    }

    .rich-text {
      display: none;
    }
  }
}

.headshot.is-full-bio {
  .headshot__list-item {
    min-width: 100%;
    max-width: 100%;

    @media screen and (min-width: 480px) {
      flex-direction: row;
      margin-bottom: $spacer * 2;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      > div:first-child {
        min-width: 33.333333%;
        max-width: 33.333333%;
      }

      .rich-text {
        padding: 0 0 0 ($spacer * 2);
      }
    }

    @include media-breakpoint-up(lg) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .headshot__cta-link-wrapper {
    display: none;
  }
}


// ============================================================================
// Overrides pages with sidebar
body.content-has-sidebar {

  .headshot {
    &.is-small-grid {
      .headshot__list-item {
        @include media-breakpoint-up(lg) {
          min-width: 50%;
          max-width: 50%;
        }

        @include media-breakpoint-up(xl) {
          min-width: 25%;
          max-width: 25%;
        }
      }
    }

    &.is-large-grid {
      .headshot__list-item {
        @include media-breakpoint-up(lg) {
          min-width: 50%;
          max-width: 50%;
        }

        @include media-breakpoint-up(xl) {
          min-width: 33.333333%;
          max-width: 33.333333%;
        }
      }
    }
  }
}
