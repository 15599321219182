.tab {
    border-bottom: 1px solid map_get($app-colors, "neutral--300");
    margin-top: $spacer * 3.125;

    @include media-breakpoint-down(lg) {
        margin-top: $spacer * 1.25;
    }
}

.tab__container {
    @include media-breakpoint-down(lg) {
        position: relative;
        width: calc(100vw - 2rem);
        margin: 0 auto;
    }
}

.tab__list {
    display: flex;
    gap: 4px;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    width: max-content;
    background-color: map_get($app-colors, "neutral--100");

    @include media-breakpoint-down(lg) {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        width: 100%;
        gap: 8px;
    }
}

.tab__control {
    width: 100%;
    padding: 0.5rem 3.25rem;
    text-transform: capitalize;
    font-weight: 400;
    color: map_get($app-colors, "neutral--100");
    background-color: map_get($app-colors, "blue");

    &:focus-visible {
        outline: 2px solid map_get($app-colors, "orange");
    }
}

.tab__item--active {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.tab__item--active .tab__control {
    color: map_get($app-colors, "neutral--900");
    background-color: #f4f4f5;
}

// Mobile dropdown
.tab__dropdown-control {
    display: none;
    position: relative;
    color: map_get($app-colors, "neutral--900");
    background-color: #f4f4f5;

    @include media-breakpoint-down(lg) {
        display: block;
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.036995 5.967737-4.17534-4.17536c-.20137-.20138-.20137-.52785 0-.7292l.48699-.48699c.20103-.20103.52684-.201417.72834-.00086l3.32462 3.30905 3.3246-3.30905c.2015-.200558.52731-.20017.728342.00086l.486987.48698c.201373.20138.201373.52785 0 .72921l-4.17532 4.17536c-.20137.20135-.52785.20135-.72922 0Z' fill='%233D4BF5'/%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 11px;
        content: "";
        display: inline-block;
        height: 7px;
        position: absolute;
        top: 45%;
        width: 11px;
        margin-left: 8px;
      }
}

.tab.is-dropdown-open {
    @include media-breakpoint-down(lg) {
        border: none;

        .tab__dropdown-control {
            border: 1px solid map_get($app-colors, "orange");
            &::after {
                transform: rotate(180deg);
            }
        }

        .tab__list {
            display: flex;
            position: absolute;
            margin-top: 8px;
            z-index: 2;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100vw;
                height: 1px;
                left: -1rem;
                background-color: map_get($app-colors, "neutral--300");;
            }
        }
    }
}