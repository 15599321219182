.app-main {

  *:last-child {
    margin-bottom: 0;
  }

  /* app-main default link focus style
  =========================================================================== */
  a {
    border-radius: .05rem;
    color: map_get($app-colors, 'blue');
    text-decoration: underline;

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 6px;
      box-shadow:
        0 0 0 2px map_get($app-colors, 'neutral--100'),
        0 0 0 4px map_get($app-colors, 'blue');
    }

    &:hover {
      text-decoration: none;
    }
  }

  > section {
    position: relative;
  }

}


.app-main__content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1550px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    padding: ($spacer * 2.5) ($spacer);
  }

  @include media-breakpoint-up(xl) {
    padding: $spacer * 2.5;
  }
}

// Complex Event Layout
.app-main__content--complex-event {
  padding-top: $spacer * 4.25;

  @include media-breakpoint-down(lg) {
    padding-top: $spacer * 1.375;
    padding-bottom: $spacer * 4;
    width: calc(100vw - 2rem);
    margin: 0 auto;

    .app-subpage-content .app-subpage-content__summary {
      border: none;
    }
  }
}


.app-main__content-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 100%;
  // overflow-x: hidden;

  .app-main__content--2col & {
    @include media-breakpoint-up(lg) {
      min-width: 50%;
      max-width: 50%;
    }
  }
}

.ruled-content-header {
  border-bottom: 2px solid map_get($app-colors, "neutral--900");
  color: map_get($app-colors, "neutral--900");
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: $spacer * .5 !important;
  padding-bottom: $spacer * .5;
  text-transform: uppercase;
}

.print-button {
  align-items: center;
  background-color: transparent;
  display: flex;
  padding: 0;
  text-transform: none;

  span {
    color: map_get($app-colors, "blue");
    // padding-left: $spacer * .5;
    text-decoration: underline;
  }
}



body.is-portal {
  .app-main {
    background-color: #110028;
    padding-top: $spacer * 2;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }
}

body.is-invoice {
  .app-main {
    background-color: #F9F8FB;
  }
}

// .app-main.app-main--invoice {
//   background-color: #F9F8FB;
// }

// .app-main.app-main--portal {
//   background-color: #110028;
//   padding-top: $spacer * 2;

//   @include media-breakpoint-up(lg) {
//     padding-top: 0;
//   }
// }
