.magazine-rack {
  @include global-component;

  > .row {
    > .col:first-child {
      > div {
        @include media-breakpoint-up(xl) {
          position: sticky;
          top: $spacer * 2;
        }
      }
    }
  }
}

.magazine-rack__eyebrow {
  @include global-component__eyebrow;
}

// ============================================================================================
// Embedded Component Overrides
.magazine-rack .routing {
  padding: 0;

  .routing__cards {
    margin-left: 0;
  }

  .routing__card {

    @include media-breakpoint-up(xl) {
      padding-left: 2rem;
    }

    &.is-featured {
      margin-bottom: 0;

      .routing__card-image-wrapper {
        display: block;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 0;
      }
    }

  }

}

