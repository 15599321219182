.member-directory {
  .loader {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

.member-directory__search-filters {
  // padding-right: $spacer * 2.5;
  // padding-bottom: $spacer * 2.5;
  // padding-left: $spacer * 2.5;
}

.member-directory__header {
  padding-top: $spacer * 2.5;
  padding-right: $spacer * 2.5;
  padding-bottom: $spacer * 1.25;
  padding-left: $spacer * 2.5;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.directory-filters__filters-wrapper {
  .search-banner__keyword-input-group {
    margin-right: 0;

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
    }

    @include media-breakpoint-up(sm) {
      border: 1px solid map_get($app-colors, "neutral--700");
    }
  }

  .search-banner__keyword-label {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--850') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--850') )}' stroke-width='.049'/%3E%3C/svg%3E");
    color: map_get($app-colors, "neutral--850");

    @include media-breakpoint-down(sm) {
      border-right: none;
      background-position: 0 50%;
      padding-left: $spacer * 1.45;
      margin-bottom: 0;
    }
  }

  .search-banner__keyword-input {
    @include media-breakpoint-down(sm) {
      width: 100%;
      border: 1px solid map_get($app-colors, "neutral--700");
    }
  }
}

.directory-filters__heading {
  font-weight: 600;
  margin-bottom: $spacer * 0.625;
}

.directory-filters__accordion-title,
.directory-listing__accordion-title {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  color: map-get($app-colors, "blue");
  padding: 0;
  padding-left: $spacer * 1.25;
  background-color: transparent;

  &:before {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.036995 5.967737-4.17534-4.17536c-.20137-.20138-.20137-.52785 0-.7292l.48699-.48699c.20103-.20103.52684-.201417.72834-.00086l3.32462 3.30905 3.3246-3.30905c.2015-.200558.52731-.20017.728342.00086l.486987.48698c.201373.20138.201373.52785 0 .72921l-4.17532 4.17536c-.20137.20135-.52785.20135-.72922 0Z' fill='%233D4BF5'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 11px;
    content: "";
    display: inline-block;
    height: 7px;
    position: absolute;
    left: 0;
    top: 40%;
    width: 11px;
  }

  &[aria-expanded="false"] {
    transform: none;
  }

  &[aria-expanded="true"] {
    &:before {
      transform-origin: center center;
      transform: rotate(180deg);
    }
  }

  &:focus {
    border-color: map_get($app-colors, "neutral--100");
    box-shadow: 0 0 0 3px map_get($app-colors, "blue"),
      0 0 0 6px map_get($app-colors, "neutral--100");
  }
}

.directory-filters__accordion-title {
  margin-top: $spacer * 1.25;
  button {
    font-size: 0.875rem;
  }
}

.directory-listings__accordion-title {
  button {
    font-size: 1.25rem;
  }
}

.directory-filters__accordion-body,
.directory-listing__accordion-body {
  transition: height 0.3s ease;
  transform-origin: top;

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    //max-height: 1000px;
    max-height:100%;
    padding-bottom: $spacer * 2.5;
  }
}

.directory-filters__accordion-body {
  padding-top: $spacer * 1.25;
}

.directory-listing__accordion-body {
  padding-left: $spacer * 1.625;
  padding-right: $spacer * 1.625;

  &[aria-hidden="false"] {
    background-color: map-get($app-colors, "orange--pale");
    padding-top: $spacer * 1.625;
    padding-bottom: $spacer * 1.625;
  }
}

.directory-filters__form-fieldset {
  ul {
    column-count: 1;
    list-style: none;
    padding-left: 0;

    @include media-breakpoint-up(md) {
      column-count: 3;
    }

    @include media-breakpoint-up(lg) {
      column-count: 2;
    }

    @include media-breakpoint-up(xl) {
      column-count: 3;
    }

    li {
      line-height: 1.4;
      margin-bottom: $spacer * .5;
      break-inside: avoid;
    }
  }
}

.directory-filters__checkbox-wrapper {
  display: flex;
  align-items: flex-start;

  input {
    margin-right: 0.5rem;
    margin-top: 0.25rem;
  }
}

.member-directory__listing,
.member-directory__no-results {
  background: map-get($app-colors, "neutral--100");
  padding: $spacer * 2.5;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-top: $spacer * 2.5
}

.member-directory__items {
  margin-bottom: $spacer * 2.25;
}

.directory-listing__card {
  padding: ($spacer * 1.875) 0;
  border-bottom: 1px solid map-get($app-colors, "gold");

  &:first-of-type {
    padding-top: 0;
  }
}

.directory-listing__name {
  color: map-get($app-colors, "purple");
  font-size: 1.25rem;
  margin-bottom: 0;
}

.directory-listing__pronouns {
  color: map-get($app-colors, "neutral--700");
  font-weight: 400;
}

.directory-listing__work {
  font-size: 1.125rem;
  margin-bottom: 0;
}

.directory-listing__industry {
  font-size: 0.875rem;
}

.directory-listing__industry-name {
  margin-left: $spacer * 0.625;
}


.directory-listing__contact-list {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    margin-right: $spacer * 1.625;
    a {
      .external-link-icon {
        @extend .visually-hidden;
      }
    }
  }

  .directory-listing__telephone {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12.629 9.187-2.844-1.22a.61.61 0 0 0-.71.176l-1.26 1.539a9.411 9.411 0 0 1-4.499-4.5l1.539-1.259a.608.608 0 0 0 .175-.71L3.81.368a.613.613 0 0 0-.698-.353l-2.64.61A.61.61 0 0 0 0 1.218C0 7.732 5.279 13 11.781 13a.61.61 0 0 0 .594-.472l.61-2.64a.617.617 0 0 0-.356-.701Z' fill='%23946B1C'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0 center;
    padding-left: $spacer * 1.5;
  }

  .directory-listing__email {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.068.346H1.761C.914.346.23 1.038.23 1.885l-.008 9.23c0 .846.692 1.539 1.539 1.539h12.307c.846 0 1.539-.693 1.539-1.539v-9.23c0-.847-.693-1.539-1.539-1.539Zm0 10.77H1.761V3.422L7.914 7.27l6.154-3.846v7.692ZM7.914 5.73 1.761 1.885h12.307L7.914 5.73Z' fill='%23946B1C'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0 center;
    padding-left: $spacer * 1.5;
  }

  .directory-listing__linkedIn {
    margin-right: 0;
    a {
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.875.063H1.122c-.585 0-1.06.48-1.06 1.072v12.73c0 .591.475 1.072 1.06 1.072h12.753a1.07 1.07 0 0 0 1.063-1.072V1.135A1.07 1.07 0 0 0 13.874.062Zm-9.317 12.75H2.354v-7.1h2.208v7.1h-.004ZM3.456 4.743a1.279 1.279 0 1 1 .001-2.558 1.279 1.279 0 0 1-.001 2.558Zm9.367 8.069h-2.205V9.358c0-.823-.017-1.882-1.146-1.882-1.149 0-1.325.896-1.325 1.823v3.512H5.943V5.715h2.115v.97h.03c.295-.559 1.016-1.146 2.088-1.146 2.231 0 2.646 1.47 2.646 3.383v3.892Z' fill='%23946B1C'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 0 center;
      padding-left: $spacer * 1.5;
    }
  }

  .directory-listing__twitter {
    a {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5.5h-11A1.5 1.5 0 0 0 0 2v11a1.5 1.5 0 0 0 1.5 1.5h11A1.5 1.5 0 0 0 14 13V2A1.5 1.5 0 0 0 12.5.5Zm-1.528 4.963c.006.087.006.178.006.265 0 2.71-2.062 5.831-5.831 5.831A5.804 5.804 0 0 1 2 10.641c.166.018.325.025.494.025.96 0 1.84-.325 2.543-.875a2.052 2.052 0 0 1-1.915-1.422c.316.047.6.047.925-.038a2.05 2.05 0 0 1-1.64-2.012v-.025c.271.153.59.247.924.26a2.045 2.045 0 0 1-.912-1.707c0-.381.1-.731.278-1.035a5.82 5.82 0 0 0 4.225 2.144 2.052 2.052 0 0 1 3.497-1.872c.462-.087.906-.259 1.3-.493a2.045 2.045 0 0 1-.9 1.128c.412-.044.812-.16 1.181-.319-.278.41-.628.772-1.028 1.063Z' fill='%23946B1C'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 0 center;
      padding-left: $spacer * 1.5;
    }

    .separator {
      display: inline-block;
      margin-right: $spacer * 0.5;
      margin-left: $spacer * 0.5;
    }
  }

  .directory-listing__linkedIn,
  .directory-listing__twitter {
    a {
      color: map-get($app-colors, "neutral--850");
      text-decoration: none;
    }
  }
}
