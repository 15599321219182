table {
    width:100%;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top:24px;
    
    
    th {
        padding:15px;
        font-size:12px;
        color: map_get($app-colors, "neutral--700");
        text-transform:uppercase;
        font-weight:bold;
        background-color: map_get($app-colors, "neutral--100");
    }

    tr {
        &:nth-child(even)  {
            background-color: map_get($app-colors, "purple--pale");
        }

        &:hover {
            background-color: map_get($app-colors, "neutral--200");
        }
    }

    td {
        padding:22px 15px;
        font-size:14px;
        line-height:1.5;
        text-transform:capitalize;
    }

}
