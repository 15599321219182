.app-portalpage-content {
  background-color: #110028;
  padding-bottom: $spacer * 2.5;
  padding-top: $spacer * 2.5;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }

  .progress {
    background-color: map_get($app-colors, 'neutral--200');
    height: $spacer * .5;

    .progress-bar {
      background-color: map_get($app-colors, 'green');
    }

    .progress-bar-label {
      font-size: .8rem;
    }
  }
}


.app-portalpage-content__primary {
  margin-bottom: $spacer * 2;
  padding: 0 $spacer ($spacer * 2) $spacer;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.app-portalpage-content__primary-inner {
  background-color: map_get($app-colors, 'neutral--100');
  border-radius: $spacer;
  margin-bottom: $spacer * 1.75;
  overflow: hidden;
  padding: $spacer * 1.5;

  @include media-breakpoint-up(lg) {
    padding: $spacer * 2;
  }
}

.app-portalpage-content__section {
  padding: $spacer 0 ($spacer * 2) 0;
}

.app-portalpage-content__header {
  font-size: 1.9rem;
}

.app-portalpage-content__subheader {
  border-bottom: 1px solid #946B1C;
  color: #946B1C;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: $spacer;
  padding-bottom: $spacer * .5;
  text-transform: uppercase;
}

.app-portalpage-content__footer {
  border-top: 1px solid #946B1C;
  padding-top: $spacer * 2;
}

.app-portalpage-content__counter {
  display: block;
  font-size: .8rem;
  padding-top: $spacer * .25;
  text-align: right;
}



.app-portalpage-content__sidebar {
  padding: 0 $spacer ($spacer * 2) $spacer;

  @include media-breakpoint-up(lg) {
    padding: 0;
    padding-right: $spacer * 2;
  }
}

.app-portalpage-content__sidebar-section {
  background-color: map_get($app-colors, 'neutral--100');
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    border-radius: $spacer;
    margin-bottom: $spacer * 1.75;
  }

  &:first-child {
    border-top-left-radius: $spacer;
    border-top-right-radius: $spacer;
  }

  &:last-child {
    border-bottom-left-radius: $spacer;
    border-bottom-right-radius: $spacer;
  }
}

.app-portalpage-content__sidebar-user-info {
  background-color: map_get($app-colors, 'orange--pale');
  padding: $spacer * 1.5;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: $spacer * 2;
  }

  &:after {
    @include media-breakpoint-up(lg) {
      background: rgb(192,135,33);
      background: -moz-linear-gradient(90deg, rgba(192,135,33,1) 5%, rgba(251,179,50,1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(192,135,33,1) 5%, rgba(251,179,50,1) 100%);
      background: linear-gradient(90deg, rgba(192,135,33,1) 5%, rgba(251,179,50,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c08721",endColorstr="#fbb332",GradientType=1);

      bottom: 0;
      content: '';
      height: 10px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}

.app-portalpage-content__sidebar-user-welcome {
  line-height: 1.4;
  margin-bottom: $spacer;
}
.app-portalpage-content__sidebar-user-salutation {
  display: block;
  color: #805710;
  font-size: 1.5rem;
  font-weight: 600;
}

.app-portalpage-content__sidebar-user-fullname {
  font-size: 1.65rem;
  font-weight: 500;
}

.app-portalpage-content__sidebar-user-pronouns {
  color: map_get($app-colors, 'neutral--800');
  padding-left: $spacer * .5;
}

.app-portalpage-content__sidebar-user-organization {
  font-weight: 600;
  margin-bottom: 0;
}

.app-portalpage-content__sidebar-user-title {
  margin-bottom: 0;
}

.app-portalpage-content__sidebar-user-membership-tier {
  color: #2D036B;
  font-size: .85rem;
  font-weight: 600;
  letter-spacing: .15rem;
  margin-bottom: $spacer * .5;
  padding-top: $spacer * .5;
  text-transform: uppercase;
}

a.app-portalpage-content__sidebar-user-edit-profile-link {
  align-items: center;
  display: flex;
  text-decoration: none;
  width: 100%;

  svg {
    max-width: 14px;
    min-width: 14px;

    path {
      fill: map_get($app-colors, 'blue');
    }
  }
  span {
    flex: 1;
    padding-left: $spacer * .8;
    text-decoration: underline;
  }

  &:hover {
    span {
      text-decoration: none;
    }
  }
}

.app-portalpage-content__sidebar-contact-cta {
  background-color: map_get($app-colors, 'orange--pale');
  border-top: 1px solid rgba(#865B11, .35);
  padding: ($spacer * .25) ($spacer * 1.5);

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    padding: $spacer * 2;
  }

  p {
    margin-bottom: $spacer * .5;
  }

  .btn {
    display: block;

    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }

}

.app-portalpage-content__sidebar-contact-cta-header {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer * .5;
  }

  > button {
    align-items: center;
    background-color: transparent;
    border: 2px solid transparent;
    color: #714809;
    display: flex;
    font-weight: inherit;
    letter-spacing: .1rem;
    padding: 0;
    text-align: left;
    text-transform: inherit;
    width: 100%;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.74 11.6061h-7.5784v7.9903H7.57837v-7.9903H0V8.43475h7.57837V.40332h3.58323v8.03143H18.74v3.17135Z' fill='%23714809'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 16px;
      margin: 0 1px;
      min-width: 16px;
      width: 16px;
    }

    &:focus {
      // background-color: map_get($app-colors, "blue--dark");
      // border-color: map_get($app-colors, "blue--dark");
      border-radius: $spacer * .25;
      box-shadow: 0 0 0 2px map_get($app-colors, "blue--dark");
    }

    &[aria-expanded="true"] {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='4' viewBox='0 0 19 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0v4h18.75V0H0Z' fill='%23714809'/%3E%3C/svg%3E");
      }
    }

    span {
      padding-left: $spacer * .8;
    }


    @include media-breakpoint-up(lg) {
      display: none;
    }

  }

  > span {
    display: none;
    letter-spacing: .2rem;

    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      width: 100%;
    }

    svg {
      max-width: 20px;
      min-width: 20px;
      max-height: 20px;

      path {
        fill: #865B11
      }
    }
    span {
      flex: 1;
      padding-left: $spacer * .8;
    }

  }
}

.accordion-content-portal-contact {
  display: none;
}

.app-portalpage-content__sidebar-contact-cta-details {
  border-top: 1px solid rgba(#865B11, .35);
  padding-top: $spacer * .5;
  padding-bottom: $spacer;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  a {
    @extend .btn, .btn-secondary;

    // &:hover {
    //   background-color: map_get($app-colors, 'neutral--100');
    // }
  }
}



.app-portalpage__sidebar-membership-cta {
  background-color: map_get($app-colors, 'green');

  @include media-breakpoint-up(lg) {
    padding: $spacer * 2;
  }

  .btn.btn-primary {
    @include media-breakpoint-down(lg) {
      background-color: transparent;
      border: 0;
      color: map_get($app-colors, 'neutral--850');
      display: block;
      font-size: 1rem;
      font-weight: 400;
      padding: ($spacer * .5) ($spacer * 1.5);
      text-align: left;
      text-transform: none;
      text-decoration: underline;
    }
    @include media-breakpoint-up(lg) {
    }
  }
}

.app-portalpage__sidebar-membership-cta-header {
  display: none;

  @include media-breakpoint-up(lg) {
    color: map_get($app-colors, 'purple--dark');
    display: block;
    font-size: 1.65rem;
    font-weight: 400;
    line-height: 1.1;
  }
}



.app-portalpage-content__sidebar-nav {
  display: none;
  position: relative;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: ($spacer * .5) 0;

    li {
      font-size: 1.1em;
      margin: 0;
      padding: 0;

      a {
        align-items: center;
        border-radius: $spacer;
        color: map_get($app-colors, 'neutral--850');
        display: flex;
        line-height: 1.3;
        padding: ($spacer * 1.5) ($spacer * 2);
        text-decoration: none;

        .external-link-icon {
          background-image: url("data:image/svg+xml,%3Csvg fill='#{encode_color( map_get($app-colors, 'neutral--800') )}' width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.4 1.4V.6c-.1-.3.2-.6.5-.6l6.8.1c.3 0 .6.3.6.6l.1 6.8c0 .3-.3.6-.6.6L10 8c-.3 0-.6-.2-.6-.6V3.3L4.7 8.1c-.2.2-.6.2-.8 0l-.6-.5c-.3-.3-.3-.7 0-.9l4.8-4.8L4 2c-.4 0-.6-.3-.6-.6Z'/%3E%3Cpath d='M2 2v7.3h9.4v1.1c0 .5-.4.9-.9.9H.6c-.1 0-.3-.1-.4-.1-.1-.2-.2-.3-.2-.5V.7C0 .6.1.4.1.3.3.1.4 0 .6 0H2v2Z'/%3E%3C/svg%3E");
          background-size: contain;
          height: 14px;
          width: 14px;
        }


        &:hover,
        &:active {
          color: map_get($app-colors, "purple--dark");

          span {
            text-decoration: underline;
          }
        }

        &:focus {
          box-shadow: inset 0 0 0 7px map_get($app-colors, "neutral--100"),
                      inset 0 0 0 10px map_get($app-colors, "blue");
          outline: 3px solid transparent;
        }

        svg {
          max-height: 18px;
          max-width: 20px;
          min-width: 20px;
        }
        span:not(.external-link-icon) {
          flex: 1;
          padding-left: $spacer * 1.5;
        }


        &.is-current {
          font-weight: 700;

          svg {
            path {
              fill: #865B11;
            }
          }
        }
      }
    }
  }
}
