// ============================================================================================
// Bootstrap Refinements

.modal-dialog {
  margin: $spacer auto;
  // margin-bottom: $spacer * 2 !important;
  max-width: 880px;
  width: 90vw;
}

.modal-content {
  background-color: map-get($app-colors, "neutral--100");
  border-radius: .5rem;
  overflow: hidden;
  padding: 0;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: $spacer * 2.5;
  }
}


.modal__header {
  background-color: map-get($app-colors, "orange--pale");
  padding: $spacer;
  // padding-bottom: ($spacer * 1.5);

  @include media-breakpoint-up(sm) {
    padding-bottom: ($spacer * 1.5);
    padding-left: $spacer * 2;
    padding-right: $spacer * 2;
    padding-bottom: $spacer;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: ($spacer * 2.5);
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;
    padding-bottom: ($spacer * 2);
  }

  .btn-close {
    position: absolute;
    right: $spacer * .75;
    top: $spacer * .75;
  }
}

.modal__header-eyebrow {
  color: map_get($app-colors, "neutral--700");
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
}

.modal__header-title {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.25;

  @include media-breakpoint-up(md) {
    font-size: 2.6rem;
  }
}

.modal__body {
  padding: $spacer;

  @include media-breakpoint-up(sm) {
    padding-bottom: $spacer * 1.5;
    padding-left: $spacer * 2;
    padding-right: $spacer * 2;
    padding-top: $spacer;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: $spacer * 2;
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;
    padding-top: $spacer * 2;
  }

  h3,
  .h3 {
    font-size: 1.65rem;
    padding-top: $spacer * 3;
  }

}

.modal__body-event-summary {
  border-bottom: 1px solid map_get($app-colors, "neutral--700");
  color: map_get($app-colors, "neutral--900");
  margin-bottom: 0;
  padding-bottom: $spacer * 1.5;

  span {
    margin-right: $spacer * .5;
    padding-right: $spacer * .8;
    border-right: 1px solid map_get($app-colors, "neutral--800");

    &:first-child {
      font-weight: 600;
    }

    &:last-child {
      border: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}
