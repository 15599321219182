/*
 * NOTE: This component is complex and has many context-specific tweaks based on that + screen size
 * The first set of styles are common to all uses up to the "sm" breakpoint
 * Following that are additional scoped styles for specific use cases/contexts
 * Be sure to test any changes on a full width template, a general subpage template with a sidebar, and the Portal Dashboard template
 * Good luck!
 */

.event-routing {
  @include global-component;
}

.event-routing__eyebrow {
  @include global-component__eyebrow;
}

.event-routing__headline {
  @include global-component__headline;
}

.event-routing__description {
  @include global-component__description;
}

.event-routing__cards {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    padding-top: $spacer * 2;
  }
}

.event-routing__card {
  background-color: map_get($app-colors, "neutral--100");
  margin-bottom: $spacer;
  padding: $spacer;
  position: relative;

  &:hover {
    a.event-routing__card-title {
      color: map_get($app-colors, "blue");
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }

  &:focus-within {
    box-shadow: inset 0 0 0 4px #ffffff,
                inset 0 0 0 7px #4e60f4;

    a.event-routing__card-title {
      color: map_get($app-colors, "blue");
      text-decoration: none;
      box-shadow: none;
    }
  }
}

.event-routing__card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.event-routing__card-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.event-routing__card-image-wrapper {
  display: flex;
  flex-direction: column;
  height: 140px;
  justify-content: center;
  margin-bottom: $spacer * 1.5;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    height: 300px;
  }
}

.event-routing__card-image {
  width: 100%;
  flex: 1;
  object-fit: cover;
  object-position: center;
}

.event-routing__card-date-box {
  display: none;
  border: 2px solid map_get($app-colors, 'orange');
  color: map_get($app-colors, 'purple');
  flex-direction: column;
  height: 73px;
  justify-content: center;
  line-height: 1;
  position: absolute;
  right: $spacer * 2;
  text-align: center;
  text-transform: uppercase;
  top: $spacer * 2;
  width: 76px;
}
.event-routing__card-date-box-month {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 0;
}
.event-routing__card-date-box-year {
  font-size: 1.35rem;
  font-weight: 500;
  margin-bottom: 0;
}
.event-routing__card-date-box-day {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 0;
}

a.event-routing__card-title {
  @include global-card-title;
  line-height: 1.4;
  margin-bottom: 0;
  text-decoration: none;

  .ellipsis {
    display: none;
    @include media-breakpoint-up(xl) {
      display: inline;
    }
  }
  .truncated {
    @include media-breakpoint-up(xl) {
      @include visually-hidden;
    }
  }

}

.event-routing__card-additional-event-info {
  font-size: .9rem;
}

.event-routing__card-footer {
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 1.4;
  padding-top: $spacer * 1.5;
}

.event-routing__card-dateline {
  @include global-card-date;
  border-bottom: 1px solid map_get($app-colors, "neutral--700");
  color: map_get($app-colors, "neutral--700");
  opacity: .8;
  margin-bottom: 0;
  padding-bottom: $spacer * .75;

  span {
    margin-right: $spacer * .3;
    padding-right: $spacer * .4;
    border-right: 1px solid map_get($app-colors, "neutral--800");

    &:last-child {
      border: 0;
      margin-right: 0;
      padding-right: 0;

    }
  }
}

.event-routing__card-date {
}

.event-routing__card-additional-date-info {
}

.event-routing__card-sponsor {
  color: map_get($app-colors, "neutral--700");
  font-weight: 600;
  margin-bottom: 0;
  opacity: .85;
  padding-top: $spacer * .75;

  &[aria-hidden=true] {
    display: none;
  }
}

.event-routing__card-pseudo-button {
  background-color: map_get($app-colors, "blue");
  color: map_get($app-colors, "neutral--100");
  font-size: 1rem;
  font-weight: 600;
  margin-top: $spacer * 1.5;
  padding: $spacer * .75 $spacer * 2;
  text-align: center;
  text-transform: uppercase;
}

.event-routing__cta-link-wrapper {
  // padding-bottom: $spacer * 1.5;
}

a.event-routing__cta-link {
  @include global-component__cta-link;
  margin-top: $spacer * 3.5;
}


.event-routing__card {

  // Adjust items when hovering over card since entre card is clickable
  &:hover {
    cursor: pointer;

    a.event-routing__card-title {
      // text-decoration: underline;
    }

    .event-routing__card-pseudo-button {
      background-color: map_get($app-colors, "blue--dark");
    }
  }
}


// ============================================================================
// Overrides for pages without sidebars (i.e. full-width Hime, Hub templates)
body:not(.content-has-sidebar):not(.is-portal) {

  @include media-breakpoint-up(md) {
    .event-routing__card {
      padding: $spacer * 2;
      &:before {
        background-color: map_get($app-colors, "orange");
        bottom: 0;
        left: -$spacer;
        content:'';
        position: absolute;
        height: 80%;
        width: $spacer;
      }
    }
    .event-routing__card-inner {
      flex-direction: row;
    }
    .event-routing__card-main {
      padding-left: $spacer * 2;
    }
    .event-routing__card-image-wrapper {
      height: initial;
      margin-bottom: 0;
      max-width: 240px;
      min-width: 240px;
      min-height: 300px;
    }
    .event-routing__card-date-box {
      display: flex;
    }
    .event-routing__card-title-info-wrapper {
      padding-right: 95px;
    }
    .event-routing__card-pseudo-button {
      display: inline-block;
    }
  }

  @include media-breakpoint-up(lg) {
    .event-routing__card {
      &:before {
        left: -$spacer * 1.5;
        width: $spacer * 1.5;
      }
    }
    .event-routing__card-image-wrapper {
      max-width: 300px;
      min-width: 300px;
      min-height: 385px;
    }
  }


  // ----------------------------------------------------------------------------
  // Overides for "double"
  .event-routing.event-routing--double {

    @include media-breakpoint-up(xxl) {

      .event-routing__cards {
        flex-direction: row;
      }

      .event-routing__card-image-wrapper {
        display: none;
      }

      .event-routing__card-main {
        justify-content: space-between;
        padding-bottom: $spacer * 3;
        padding-top: $spacer * 3;
      }

      .event-routing__card-sponsor {
        &[aria-hidden=true] {
          display: block;
        }
      }

      .event-routing__card {
        margin-bottom: 0;

        &:nth-child(1) {
          max-width: 66%;
          min-width: 66%;

          .event-routing__card-image-wrapper {
            display: flex;
          }
        }
        &:nth-child(2) {
          flex: 1;
          margin-left: $spacer * 2;
          &:before {
            display: none;
          }
          .event-routing__card-main {
            padding-left: 0;
          }
        }
      }

    }

  }


  // ----------------------------------------------------------------------------
  // Overides for "triple"
  .event-routing.event-routing--triple {

    @include media-breakpoint-up(xl) {
      .event-routing__cards {
        display: grid;
        grid-template-columns: 50% 2rem auto;
        grid-template-rows: min-content 2rem min-content;
        flex-direction: initial;
      }

      .event-routing__card-image-wrapper {
        display: none;
      }

      .event-routing__card-main {
        padding-left: 0;
        padding-bottom: $spacer;
        padding-top: $spacer;
      }

      .event-routing__card {
        margin-bottom: 0;

        &:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 4;

          .event-routing__card-inner {
            flex-direction: column;
          }

          .event-routing__card-date-box {
            top: 365px;
          }

          .event-routing__card-image-wrapper {
            display: flex;
            margin-bottom: $spacer * 2;
            min-height: 300px;
            max-height: 300px;
            max-width: 100%;
            min-width: 100%;
          }

          .event-routing__card-dateline {
            padding-right: 95px;
          }

        }

        &:nth-child(2) {
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 2;

          &:before {
            display: none;
          }

          .event-routing__card-main {
            justify-content: space-around;
          }

          .event-routing__card-pseudo-button {
            display: none;
          }
        }

        &:nth-child(3) {
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 3;
          grid-row-end: 4;

          &:before {
            display: none;
          }

          .event-routing__card-main {
            justify-content: space-around;
          }

          .event-routing__card-pseudo-button {
            display: none;
          }
        }

      }
    }

    @include media-breakpoint-up(xxl) {
      .event-routing__cards {
        grid-template-columns: 66% 2rem auto;
      }

      .event-routing__card {

        &:nth-child(1) {
          .event-routing__card-inner {
            flex-direction: row;
          }

          .event-routing__card-date-box {
            top: $spacer * 2;
          }

          .event-routing__card-main {
            padding-left: $spacer * 2;
            padding-top: $spacer * 5.5;
            padding-bottom: $spacer * 5;
          }

          .event-routing__card-title-info-wrapper {
            padding-right: 0;
          }

          .event-routing__card-image-wrapper {
            margin-bottom: 0;
            max-height: 100%;
            max-width: 380px;
            min-width: 380px
          }


          .event-routing__card-dateline {
            padding-right: 0;
          }

        }
      }
    }

  }

}



// ============================================================================
// Overrides for pages with sidebars (i.e. General Subpage, Portal Dashboard templates)
body.content-has-sidebar,
body.is-portal {

  @include media-breakpoint-up(xl) {
    // ----------------------------------------------------------------------------
    // Overides for "double"
    // .event-routing {
      a.event-routing__card-title {
        .ellipsis {
          display: none;
        }
        .truncated {
          // Override the default state that comes from the "@include visually-hidden" above
          position: initial !important;
          width: initial !important;
          height: initial !important;
          padding: initial !important;
          margin: initial !important;
          overflow: initial !important;
          clip: initial !important;
          white-space: initial !important;
          border: initial !important;
        }
      }
    // }
  }

  @include media-breakpoint-up(xxl) {
    .event-routing__card {
      padding: $spacer * 2;
      &:before {
        background-color: map_get($app-colors, "orange");
        bottom: 0;
        left: -$spacer * 2;
        content:'';
        position: absolute;
        height: 80%;
        width: $spacer * 2;
      }
    }
    .event-routing__card-inner {
      flex-direction: row;
    }
    .event-routing__card-main {
      padding-left: $spacer * 2;
    }
    .event-routing__card-image-wrapper {
      height: initial;
      margin-bottom: 0;
      max-width: 240px;
      min-width: 240px;
      min-height: 300px;
    }
    .event-routing__card-date-box {
      display: flex;
    }
    .event-routing__card-title-info-wrapper {
      padding-right: 95px;
    }
    .event-routing__card-additional-event-info {
      padding-right: 95px;
    }
    .event-routing__card-pseudo-button {
      display: inline-block;
    }

    // ----------------------------------------------------------------------------
    // Overides for "triple"
    .event-routing.event-routing--triple {

      .event-routing__cards {
        display: grid;
        grid-template-columns: 50% 2rem auto;
        grid-template-rows: min-content 2rem min-content;
        flex-direction: initial;
      }
      .event-routing__card-image-wrapper {
        display: none;
      }
      .event-routing__card-main {
        justify-content: space-between;
        padding-left: 0;
        padding-bottom: $spacer;
        padding-top: $spacer;
      }
      .event-routing__card {
        margin-bottom: 0;

        &:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 4;

          .event-routing__card-main {
            padding-top: 0;
          }
          .event-routing__card-inner {
            flex-direction: column;
          }
          .event-routing__card-date-box {
            top: 300px;
          }
          .event-routing__card-image-wrapper {
            display: flex;
            margin-bottom: $spacer * 2;
            min-height: 240px;
            max-height: 240px;
            max-width: 100%;
            min-width: 100%;
          }
        }

        &:nth-child(2) {
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 2;

          &:before {
            display: none;
          }

          .event-routing__card-main {
            justify-content: space-around;
          }
          .event-routing__card-pseudo-button {
            display: none;
          }
        }

        &:nth-child(3) {
          grid-column-start: 3;
          grid-column-end: 4;
          grid-row-start: 3;
          grid-row-end: 4;

          &:before {
            display: none;
          }

          .event-routing__card-main {
            justify-content: space-around;
          }
          .event-routing__card-pseudo-button {
            display: none;
          }
        }
      }

      a.event-routing__card-title {
        .ellipsis {
          display: inline;
        }
        .truncated {
          @include visually-hidden;
        }
      }

    }

  }


  // ----------------------------------------------------------------------------
  // Additional overrides for Portal Dashboard
  .app-portalpage-content {
    .event-routing {
      margin-bottom: $spacer * 4;
      padding: 0 $spacer;
    }
    .event-routing__cards {
      margin-bottom: $spacer * 2;
      padding-top: 0;
    }
    .event-routing__card {
      border-radius: $spacer;
      &:before {
        display: none;
      }
    }
    .event-routing__eyebrow {
      display: none;
    }
    .event-routing__headline {
      @include global-component__headline--portal;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.25 18.125C.25 19.16 1.09 20 2.125 20h13.75c1.035 0 1.875-.84 1.875-1.875V7.5H.25v10.625Zm12.5-7.656a.47.47 0 0 1 .469-.469h1.562a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469H13.22a.47.47 0 0 1-.469-.469V10.47Zm0 5a.47.47 0 0 1 .469-.469h1.562a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469H13.22a.47.47 0 0 1-.469-.469V15.47Zm-5-5A.47.47 0 0 1 8.219 10H9.78a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469H8.22a.47.47 0 0 1-.469-.469V10.47Zm0 5A.47.47 0 0 1 8.219 15H9.78a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469H8.22a.47.47 0 0 1-.469-.469V15.47Zm-5-5A.47.47 0 0 1 3.219 10H4.78a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469H3.22a.47.47 0 0 1-.469-.469V10.47Zm0 5A.47.47 0 0 1 3.219 15H4.78a.47.47 0 0 1 .469.469v1.562a.47.47 0 0 1-.469.469H3.22a.47.47 0 0 1-.469-.469V15.47ZM15.875 2.5H14V.625A.627.627 0 0 0 13.375 0h-1.25a.627.627 0 0 0-.625.625V2.5h-5V.625A.627.627 0 0 0 5.875 0h-1.25A.627.627 0 0 0 4 .625V2.5H2.125C1.09 2.5.25 3.34.25 4.375V6.25h17.5V4.375c0-1.035-.84-1.875-1.875-1.875Z' fill='#{encode_color( map_get($app-colors, 'orange') )}'/%3E%3C/svg%3E");
    }
    .event-routing__description {
      color: map_get($app-colors, "neutral--100");
      display: none;
    }

    a.event-routing__cta-link {
      color: map_get($app-colors, 'neutral--100');
      margin-top: 0;

      &:hover,
      &:focus {
        color: map_get($app-colors, "neutral--100");
      }
    }

  }
}
