.accordion {
  @include global-component;
  padding: $spacer;

  @include media-breakpoint-up(md) {
    padding: $spacer;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.accordion__group {
  border-top: 1px solid map_get($app-colors, 'neutral--800');
}

.accordion__group-item {

}


.accordion__group-item-title {
  background-color: map_get($app-colors, 'neutral--100');
  border-bottom: 1px solid map_get($app-colors, 'neutral--800');
  color: map_get($app-colors, 'purple');
  display: flex;
  align-items: center;
  padding: ($spacer * 1.5) $spacer;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
  text-align: left;
  text-transform: none;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
    padding: ($spacer * 1.5) ($spacer * 4);
  }

  &:before {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.74 11.6061h-7.5784v7.9903H7.57837v-7.9903H0V8.43475h7.57837V.40332h3.58323v8.03143H18.74v3.17135Z' fill='%235162f4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0 50%;
    content: '';
    display: block;
    left: $spacer * 3;
    top: 1.5rem;
    height: 20px;
    margin-bottom: $spacer * .5;
    width: 20px;
    min-width: 20px;
  }

  &:focus {
    outline: 3px solid transparent;
    background-color: map_get($app-colors, "neutral--100");
    border-color: map_get($app-colors, "blue--dark");
    box-shadow: inset 0 0 0 3px map_get($app-colors, "neutral--100"),
                inset 0 0 0 6px map_get($app-colors, "blue--dark");
  }

  &[aria-expanded="true"] {
    border-bottom-color: map_get($app-colors, 'neutral--400');

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='4' viewBox='0 0 19 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0v4h18.75V0H0Z' fill='%235162f4'/%3E%3C/svg%3E");
    }

  }
}

.accordion__group-item-title-text {
  padding-left: 1rem;
}

.accordion__group-item-content {
  padding: ($spacer * 1.5) $spacer;

  @include media-breakpoint-up(md) {
    padding: $spacer ($spacer * 4) ($spacer * 4) ($spacer * 6);
  }
}



// ============================================================================================
// Embedded Component Overrides
.accordion .rich-text {
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

