.search-filters {
  background-color: map_get($app-colors, "neutral--200");
  padding-top: $spacer * 1.25;

  @include media-breakpoint-up(md) {
    padding-top: $spacer * 2.25;
    padding-right: $spacer * 2;
  }
}

.search-filters__filters-form-inner {
  margin-top: $spacer * 0.625;
  margin-bottom: $spacer * 0.625;
}

.search-filters__heading,
.search-filters__filter-buttons {
  padding-left: $spacer * 1.875;

  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 3.75;
    padding-top: $spacer;
  }
}

.search-filters__heading {
  font-weight: 700;
  padding-bottom: $spacer * 0.5;
  border-bottom: 1px solid map_get($app-colors, "neutral--800");

  p {
    font-size: 14px;
    text-transform: uppercase;
  }
}

.search-filters__form-fieldset {
  margin-top: $spacer * 0.625;
  margin-bottom: $spacer * 0.625;
}

.search-filters__filter-list {
  padding: 0;
  list-style: none;
}

.search-filters__filter-list-item {
  align-items: center;
  display: flex;
  margin-bottom: $spacer * .25;
  position: relative;

  input[type=checkbox] {
    left: 0;
    margin-right: $spacer * 0.5;
    position: absolute;
    top: 5px;

    &:focus {
      border-color: map_get($app-colors, "neutral--100");
      box-shadow: 0 0 0 3px map_get($app-colors, "blue"),
                  0 0 0 6px map_get($app-colors, "neutral--100");
      outline: 3px solid transparent;
    }
  }

  label {
    margin-bottom: $spacer * .5;
    padding-left: $spacer * 1.25;
  }
}

.search-filters__filter-buttons {
  a.btn,
  button.btn,
  button {
    font-size: 0.75rem;
    margin-bottom: $spacer !important;
  }
}

.search-filters__accordion-title {
  width: 100%;
  background-color: map_get($app-colors, "neutral--200");
  text-align: left;
  border-bottom: 1px solid $border;
  background-position: 95% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  padding-top: $spacer;
  padding-bottom: $spacer;
  padding-left: $spacer * 1.875;

  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 3.75;
  }

  &[aria-expanded="false"] {
    background-image: url("../images/plus-icon.svg");
  }

  &[aria-expanded="true"] {
    background-image: url("../images/minus-icon.svg");
  }

  &:focus {
    border-color: map_get($app-colors, "neutral--100");
    box-shadow: 0 0 0 3px map_get($app-colors, "blue"),
      0 0 0 6px map_get($app-colors, "neutral--100");
  }
}

.search-filters__count {
  display: inline-block;
  margin-left: $spacer * 0.65;
  padding-right: $spacer * 0.65;
  padding-left: $spacer * 0.65;
  border-radius: 7px;
  background-color: map_get($app-colors, "blue");
  color: map_get($app-colors, "neutral--100");
}

.search-filters__accordion-body {
  transition: all 0.25s ease;
  transform-origin: top;
  padding-left: $spacer * 1.875;

  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 3.75;
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
  }
}

.search-filters__form-submit {
  margin-right: $spacer * 0.625;
}
