.app-header {
  background-color: map_get($app-colors, "neutral--100");
  position: relative;
  transition: box-shadow 0.25s;
  z-index: 100;

  @include media-breakpoint-down(lg) {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;

    &.is-scrolled {
      box-shadow: 0 -1px 7px 2px rgba(0, 0, 0, 0.3);
    }
  }
}

.app-header__alert-banner {
  align-items: center;
  background-color: map_get($app-colors, "red--dark");
  display: none;
  justify-content: center;
  line-height: 1.2;
  position: relative;

  .btn-close {
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.03.646a.5.5 0 1 0-.708.708l9.662 9.662-9.662 9.662a.5.5 0 1 0 .707.707l9.662-9.662 8.955 8.955a.5.5 0 0 0 .707-.707l-8.955-8.955 8.956-8.955a.5.5 0 0 0-.708-.707l-8.955 8.954L1.03.646Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border-radius: 50%;
    padding: $spacer * .5;
    position: absolute;
    right: 6px;

    &:hover {
      background-color: darken(map_get($app-colors, "red--dark"), 10%);
    }
  }

  a,
  button {
    &:focus {
      outline: 3px solid transparent;
      box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100");
    }
  }

  &.is-visible {
    display: flex;
  }
}

.app-header__alert-banner-content {
  background-image: url("data:image/svg+xml, %3Csvg width='21' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.275 15.89c.64 1.11-.164 2.499-1.444 2.499H2.17c-1.283 0-2.084-1.39-1.444-2.5L9.056 1.444c.642-1.112 2.248-1.11 2.888 0l8.33 14.445ZM10.5 12.902a1.597 1.597 0 1 0 0 3.194 1.597 1.597 0 0 0 0-3.194ZM8.984 7.162l.257 4.722a.417.417 0 0 0 .416.394h1.686a.417.417 0 0 0 .416-.394l.257-4.722a.417.417 0 0 0-.416-.44H9.4c-.24 0-.43.2-.416.44Z' fill='%23fff' /%3E%3C/svg%3E");
  background-position: $spacer 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  color: map_get($app-colors, "neutral--100");
  padding: ($spacer * 1.25) ($spacer * 3.5);

  *:last-child {
    margin-bottom: 0;
  }

  a {
    color: map_get($app-colors, "neutral--100");

    &:hover {
      color: map_get($app-colors, "neutral--100");
      text-decoration: none;
    }
  }

  .external-link-icon {
    background-image: url("data:image/svg+xml,%3Csvg fill='#{encode_color( map_get($app-colors, 'neutral--100') )}' width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.4 1.4V.6c-.1-.3.2-.6.5-.6l6.8.1c.3 0 .6.3.6.6l.1 6.8c0 .3-.3.6-.6.6L10 8c-.3 0-.6-.2-.6-.6V3.3L4.7 8.1c-.2.2-.6.2-.8 0l-.6-.5c-.3-.3-.3-.7 0-.9l4.8-4.8L4 2c-.4 0-.6-.3-.6-.6Z'/%3E%3Cpath d='M2 2v7.3h9.4v1.1c0 .5-.4.9-.9.9H.6c-.1 0-.3-.1-.4-.1-.1-.2-.2-.3-.2-.5V.7C0 .6.1.4.1.3.3.1.4 0 .6 0H2v2Z'/%3E%3C/svg%3E") !important;
    margin-right: .75rem !important;
  }
}

.app-header__small-screen-nav-panel-trigger {
  line-height: 0;
  padding: 10px;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  .hamburger-box {
    width: 32px;
  }

  .hamburger-inner,
  &.is-active .hamburger-inner {
    background-color: map_get($app-colors, "blue");
    width: 32px;
    &::before {
      background-color: map_get($app-colors, "blue");
      width: 32px;
    }
    &::after {
      background-color: map_get($app-colors, "blue");
      width: 32px;
    }
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):active {
    .hamburger {
      opacity: 1;
    }
    .hamburger-inner,
    &.is-active .hamburger-inner {
      background-color: map_get($app-colors, "orange");
      &::before {
        background-color: map_get($app-colors, "orange");
      }
      &::after {
        background-color: map_get($app-colors, "orange");
      }
    }
  }

  &:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100"),
      0 0 0 6px map_get($app-colors, "orange");
  }
}

.app-header__utility-nav-wrapper {
  border-bottom: 1px solid map_get($app-colors, "neutral--400");
  display: none;
  font-size: 0.9rem;
  padding: 0 ($spacer * 2);

  @include media-breakpoint-up(lg) {
    display: block;
  }

  .app-header__utility-nav-list {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > .app-header__utility-nav-item {
      margin-bottom: 0;
      padding: 0.5rem;
      position: relative;

      &.is-current {
        &::after {
          background-color: map_get($app-colors, "orange");
          bottom: 0;
          content: "";
          height: 3px;
          left: 0;
          position: absolute;
          right: 0;
          width: 100%;
        }
        a,
        button {
          // background-color: map_get($app-colors, "orange");
          // color: map_get($app-colors, "neutral--900");
        }
      }

      > a,
      > button {
        background-color: transparent;
        border: 0;
        color: map_get($app-colors, "neutral--850");
        display: block;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.75;
        padding: 0 0.25rem;
        text-decoration: none;
        text-transform: none;

        &:hover {
          text-decoration: underline;
        }

        &:focus {
          border-radius: 0.05rem;
          box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100"),
            0 0 0 5px map_get($app-colors, "blue");
          outline-color: transparent;
        }

        &.has-icon {
          background-position: 100% 50%;
          background-repeat: no-repeat;
          background-size: 12px;

          &.has-icon-search {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='search' class='svg-inline--fa fa-search fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M505 442.7 405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
            background-position: 0 50%;
            padding-left: 1.5rem;
          }

          &.has-icon-signout {
            background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.806 6.487 9.14 11.298c-.416.43-1.139.13-1.139-.487v-2.75H4.222a.676.676 0 0 1-.666-.687v-2.75c0-.38.297-.686.666-.686H8v-2.75c0-.613.72-.917 1.14-.487l4.666 4.812a.709.709 0 0 1 0 .974Zm-8.472 4.668V10.01A.34.34 0 0 0 5 9.666H2.667a.902.902 0 0 1-.89-.917V3.25c0-.507.398-.916.89-.916H5a.34.34 0 0 0 .334-.344V.844A.34.34 0 0 0 5 .501H2.667C1.195.5 0 1.732 0 3.25v5.498c0 1.518 1.194 2.75 2.667 2.75H5a.34.34 0 0 0 .334-.344Z' fill='%2328262E'/%3E%3C/svg%3E");
            padding-right: 1.75rem;
          }

          &.has-icon-user {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='user' class='svg-inline--fa fa-user fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'/%3E%3C/svg%3E");
            padding-right: 1.75rem;
          }

          &.has-icon-chevron-down {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M207.029 381.476 12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
            padding-right: 1.75rem;
          }

          &.has-icon-chevron-up {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-up' class='svg-inline--fa fa-chevron-up fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='m240.971 130.524 194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'/%3E%3C/svg%3E");
            padding-right: 1.75rem;
          }

          &.has-icon-chevron-left {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-left' class='svg-inline--fa fa-chevron-left fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M34.52 239.03 228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'/%3E%3C/svg%3E");
            background-position: 0 50%;
            padding-left: 1.5rem;
          }

          &.has-icon-chevron-right {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M285.476 272.971 91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'/%3E%3C/svg%3E");
            padding-right: 1.75rem;
          }
        }
      }

      &:last-child {
        border-left: 1px solid map_get($app-colors, "neutral--400");
      }
    }
  }
}

.app-header__global-search-panel {
  left: 0;
  outline: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  &[aria-hidden="true"] {
    display: none;
  }
}

.app-header__global-search-panel-inner {
  background: map_get($app-colors, "neutral--200");
  padding: ($spacer * 8) ($spacer * 2);
}

.app-header__global-search-panel-logo {
  width: 147px;
  height: 51px;
  position: absolute;
  left: $spacer * 2;
  top: $spacer;
}

.app-header__global-search-panel-close-button {
  background-color: transparent;
  border: 0;
  border-radius: 100%;
  height: 39px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: $spacer * 2;
  top: $spacer;
  width: 39px;
  z-index: 1;

  &::before,
  &::after {
    background-color: map_get($app-colors, "neutral--850");
    content: " ";
    height: 28px;
    left: 19px;
    position: absolute;
    top: 5px;
    width: 1px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: map_get($app-colors, "neutral--100");
  }

  &:focus {
    outline: 2px solid transparent;
    box-shadow: 0 0 0 3px map_get($app-colors, "blue"),
      0 0 0 5px map_get($app-colors, "neutral--100");
  }
}

.app-header__global-search-panel-header {
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: $spacer * 1.5;
  text-align: center;
}

.app-header__global-search-form {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 700px;
}

.app-header__global-search-input-wrapper {
  align-items: center;
  background-color: map_get($app-colors, "neutral--100");
  border: 1px solid $border;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-right: $spacer;

  label {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--850') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--850') )}' stroke-width='.049'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 18px 50%;
    color: map_get($app-colors, "neutral--850");
    border-right: 1px solid map_get($app-colors, "neutral--850");
    padding-left: $spacer * 2.75;
    padding-right: $spacer * 0.625;
    font-size: 1.125rem;
  }

  input[type="text"] {
    padding: 0.675rem;
    flex: 1;
    border: none;
    font-size: 1.125rem;
    flex: 1;
  }

  button {
    font-size: 0.75rem;
  }
}

.app-header__small-screen-portal-menu-wrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 0 ($spacer * 0.5) 0 ($spacer * 0.75);
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 0.9rem;
  }

  @include media-breakpoint-up(md) {
    margin-right: $spacer;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.app-header__utility-nav-portal-menu-trigger {
  // These stles are only applied for small screens - for when it is injected into app-header__primary-banner
  background-color: transparent;
  border: 1px solid map_get($app-colors, "neutral--850");
  color: map_get($app-colors, "neutral--850");
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.3rem 2rem 0.3rem 0.5rem;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;

  @include media-breakpoint-up(sm) {
    font-size: 0.9rem;
    padding: 0.3rem 2rem 0.3rem 1.25rem;
  }

  @include media-breakpoint-up(md) {
  }

  &.has-icon {
    background-position: 93% 50%;
    background-repeat: no-repeat;
    background-size: 12px;

    &.has-icon-chevron-down {
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M207.029 381.476 12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E");
      padding-right: 1.75rem;
    }

    &.has-icon-chevron-up {
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='chevron-up' class='svg-inline--fa fa-chevron-up fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='m240.971 130.524 194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'/%3E%3C/svg%3E");
      padding-right: 1.75rem;
    }

    &:focus {
      // background-color: map_get($app-colors, "blue--dark");
      border-color: map_get($app-colors, "blue--dark");
      box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100"),
        0 0 0 6px map_get($app-colors, "blue--dark");
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.app-header__utility-nav-portal-menu {
  background-color: map_get($app-colors, "neutral--100");
  box-shadow: 0 0 15px 0 rgba(map_get($app-colors, "neutral--800"), 0.5);
  display: none;
  right: 0;
  padding: $spacer ($spacer * 1.25);
  position: absolute;
  top: 100%;
  width: 280px;
  z-index: 1;

  &.is-open {
    display: block;
    z-index: 99;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;

      a {
        color: map_get($app-colors, "neutral--850");
        display: block;
        font-size: 0.85rem;
        font-weight: 400;
        padding: ($spacer * 0.35) 0;
        position: relative;
        text-decoration: none;

        .external-link-icon {
          background-image: url("data:image/svg+xml,%3Csvg fill='#{encode_color( map_get($app-colors, 'neutral--800') )}' width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.4 1.4V.6c-.1-.3.2-.6.5-.6l6.8.1c.3 0 .6.3.6.6l.1 6.8c0 .3-.3.6-.6.6L10 8c-.3 0-.6-.2-.6-.6V3.3L4.7 8.1c-.2.2-.6.2-.8 0l-.6-.5c-.3-.3-.3-.7 0-.9l4.8-4.8L4 2c-.4 0-.6-.3-.6-.6Z'/%3E%3Cpath d='M2 2v7.3h9.4v1.1c0 .5-.4.9-.9.9H.6c-.1 0-.3-.1-.4-.1-.1-.2-.2-.3-.2-.5V.7C0 .6.1.4.1.3.3.1.4 0 .6 0H2v2Z'/%3E%3C/svg%3E");
        }

        &:hover {
          text-decoration: underline;
        }

        &::after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      > a,
      > button {
        &:focus {
          outline: 3px solid transparent;
          border-color: map_get($app-colors, "neutral--850");
          box-shadow: 0 0 0 3px map_get($app-colors, "neutral--850");
        }
      }

      &:last-child {
        border-top: 1px solid map_get($app-colors, "neutral--400");
        margin-top: $spacer * 0.5;
        padding-top: $spacer * 0.45;

        a {
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='sign-out-alt' class='svg-inline--fa fa-sign-out-alt fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{encode_color( map_get($app-colors, 'neutral--800') )}' d='M497 273 329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z'/%3E%3C/svg%3E");
            content: "";
            background-size: cover;
            display: inline-block;
            height: 12px;
            margin-left: 0.5rem;
            margin-right: 0.25rem;
            width: 12px;
          }
        }
      }
    }
  }
}

.app-header__primary-banner {
  align-items: center;
  border-bottom: 1px solid map_get($app-colors, "neutral--400");
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100%;
  padding: $spacer 0 $spacer ($spacer * 0.25);
  position: relative;

  @include media-breakpoint-up(lg) {
    border-bottom: 0;
    padding: 0 ($spacer * 2);
  }

  .app-header__logo {
    display: block;
    width: 130px;

    @include media-breakpoint-up(xl) {
      width: 150px;
    }
  }

  .app-header__primary-nav-wrapper {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      height: 90px;
    }
  }

  .app-header__primary-nav {
    display: flex;
  }

  .app-header__primary-nav-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > .app-header__primary-nav-item {
      display: flex;
      margin-bottom: 0;
      padding: 0 ($spacer * 0.25);

      @include media-breakpoint-up(xl) {
        padding: 0 ($spacer * 0.25);
      }

      > button {
        background-color: transparent;
        border-bottom: 3px solid transparent;
        color: map_get($app-colors, "neutral--850");
        display: block;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        padding: ($spacer * 0.5) $spacer;
        position: relative;
        text-transform: none;
        white-space: nowrap;

        // border-radius: $spacer * .5;
        // box-shadow: 0 0 0 2px map_get($app-colors, "blue--dark");

        @include media-breakpoint-up(xl) {
          font-size: 1.05rem;
          padding-left: $spacer * 0.75;
          padding-right: $spacer * 1.65;
        }

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.036995 5.967737-4.17534-4.17536c-.20137-.20138-.20137-.52785 0-.7292l.48699-.48699c.20103-.20103.52684-.201417.72834-.00086l3.32462 3.30905 3.3246-3.30905c.2015-.200558.52731-.20017.728342.00086l.486987.48698c.201373.20138.201373.52785 0 .72921l-4.17532 4.17536c-.20137.20135-.52785.20135-.72922 0Z' fill='%233D4BF5'/%3E%3C/svg%3E");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 11px;
          content: "";
          display: inline-block;
          height: 7px;
          position: absolute;
          right: 2px;
          top: 45%;
          width: 11px;

          @include media-breakpoint-up(xl) {
            right: 10px;
          }
        }

        &:hover {
          text-decoration: none;

          &::before {
            background-color: map_get($app-colors, "blue");
            top: 100%;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;

            @include media-breakpoint-up(xl) {
              bottom: 0;
            }
          }
        }

        &:focus {
          border-radius: $spacer * 0.5;
          box-shadow: 0 0 0 2px map_get($app-colors, "blue--dark");
        }
      }

      &.is-open {
        > button {
          // border-bottom: 3px solid map_get($app-colors, 'blue');
          color: map_get($app-colors, "blue");

          &::before {
            background-color: map_get($app-colors, "blue");
            top: 100%;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;

            @include media-breakpoint-up(xl) {
              bottom: 0;
            }
          }

          &::after {
            transform-origin: center center;
            transform: rotate(180deg);
          }
        }
        .app-header__primary-nav-item-panel {
          display: block;
        }
      }
    }
  }

  .app-header__primary-nav-item-panel {
    display: none;
    border-top: 1px solid map_get($app-colors, "neutral--400");
    background-color: map_get($app-colors, "neutral--100");
    box-shadow: 0 7px 22px 0px rgb(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 1;
  }

  .app-header__primary-nav-item-panel-header {
    display: none;
  }

  .app-header__primary-nav-item-panel-content {
    display: flex;
    flex-direction: row;

    .app-header__primary-nav-item-panel-content-column-wrapper {
      display: flex;
      flex: 1 0 0%;
      justify-content: center;

      .app-header__primary-nav-item-panel-content-column {
        padding: $spacer * 2;
        padding-bottom: $spacer * 4;
        // width: 80%;

        @include media-breakpoint-up(lg) {
          width: 70%;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }

        a {
          color: map_get($app-colors, "neutral--850");
          display: inline-block;
          line-height: 1.4;
          padding: ($spacer * 0.5) 0;
          text-decoration: none;

          &:hover {
            color: map_get($app-colors, "blue");
            text-decoration: underline;
          }
        }

        .app-header__primary-nav-item-panel-overview-link-wrapper {
          a {
            font-weight: 600;
            position: relative;

            &::after {
              background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.67693.736776.54503-.559194c.23078-.236776.60395-.236776.83228 0L10.8269 5.07179c.2308.23677.2308.61965 0 .8539L6.05424 10.8224c-.23078.2368-.60396.2368-.83228 0l-.54503-.5592c-.23323-.2393-.22832-.6297.00982-.86396l2.95838-2.89168H.58922C.262694 6.50756 0 6.23804 0 5.90302v-.80604c0-.33502.262694-.60454.58922-.60454h7.05591L4.68675 1.60076c-.24059-.23426-.2455-.624689-.00982-.863984Z' fill='%235162F4'/%3E%3C/svg%3E");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 11px;
              content: "";
              height: 11px;
              position: absolute;
              right: -20px;
              top: 50%;
              transform: translateY(-50%);
              width: 11px;
            }
          }
        }


        .app-header__primary-nav-item-panel-link-list-header {
          display: inline-block;
          font-size: 0.75rem;
          text-transform: uppercase;
          font-weight: 600;
          margin-top: $spacer * 0.5;
          position: relative;

          &::after {
            background-color: map_get($app-colors, "orange");
            content: "";
            display: inline-block;
            height: 2px;
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
          }
        }

        .app-header__primary-nav-item-panel-link-list {
          font-size: 0.95rem;
        }
      }
    }
  }
  // Overrides for when featured content exists
  .app-header__primary-nav-item-panel-content.has-featured {
    .app-header__primary-nav-item-panel-content-column-wrapper {
      &:first-child {
        justify-content: flex-end;
      }
      &:last-child {
        background-color: #f9f9ff;
        justify-content: flex-start;
        .app-header__primary-nav-item-panel-content-column {
          display: flex;
          max-width: 600px;
          padding: $spacer * 4;
          padding-right: $spacer * 2;
          width: 100%;

          > div {
            flex: 1 0 0%;

            &:last-child {
              padding-left: $spacer * 2;
            }
          }

          .app-header__primary-nav-item-panel-content-featured-header {
            font-size: 1.1rem;
            line-height: 1.3;
            margin-bottom: $spacer * 0.25;

            a {
              color: map_get($app-colors, "neutral--850");
              padding-right: 18px;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
          .app-header__primary-nav-item-panel-content-featured-description {
            font-size: 0.9rem;
            line-height: 1.4;
          }
          .app-header__primary-nav-item-panel-content-featured-image-wrapper {
            position: relative;
            padding-bottom: 60%;

            img {
              filter: grayscale(100%) contrast(125%);
              object-fit: cover;
              position: absolute;
              height: 100%;
              width: 100%;
            }
            &::after {
              background-color: #333986;
              mix-blend-mode: screen;
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              transition: opacity 0.25s;
            }
          }
        }
      }
    }
  }

  .app-header__primary-nav-cta {
    display: none;

    .btn {
      font-size: 0.75rem;
      font-weight: 600;
    }

    @include media-breakpoint-up(lg) {
      display: block;

      .btn {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    @include media-breakpoint-up(xl) {
      .btn {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}

// ================================================================================================
// Mobile panel overrides
.app-header__small-screen-nav-panel {
  background-color: map_get($app-colors, "neutral--100");
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: -100%;
  top: 0;
  width: 100%;
  z-index: 1;
  transition: left 0.2s;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &.is-open {
    left: 0;
    right: 0;
    padding-top: 80px;
  }

  .app-header__small-screen-nav-panel-header {
    align-items: center;
    background-color: map_get($app-colors, "neutral--100");
    display: flex;
    flex-direction: row-reverse;
    height: 80px;
    justify-content: space-between;
    padding-right: 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 150;

    &.is-back-to-main {
      background-color: map_get($app-colors, "neutral--200");
    }

    .app-header__small-screen-nav-panel-back-button,
    .app-header__small-screen-nav-panel-search-button {
      background-color: transparent;
      color: map_get($app-colors, "neutral--800");
      display: block;
      font-size: 0.85rem;
      font-weight: normal;
      padding: $spacer ($spacer * 2.25);
      position: relative;
      text-decoration: none;
      text-transform: none;

      &:focus {
        background-color: map_get($app-colors, "blue--dark");
        border-color: map_get($app-colors, "blue--dark");
        box-shadow: inset 0 0 0 3px map_get($app-colors, "neutral--100"),
          inset 0 0 0 6px map_get($app-colors, "blue--dark");
        color: map_get($app-colors, "neutral--100");
      }

      &::before {
        content: "";
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 16px;
        position: absolute;
        left: $spacer * 0.75;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
      }

      &.app-header__small-screen-nav-panel-back-button {
        display: none;
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' viewBox='0 0 7 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.62297 4.640213 4.79833.464873c.20138-.20137.52785-.20137.7292 0l.486991.48699c.20103.20103.201416.52684.00086.72834l-3.30905 3.32462 3.30905 3.3246c.200557.2015.20017.52731-.00086.728342l-.48698.486987c-.20138.201373-.52785.201373-.72921 0L.62297 5.369432c-.20135-.20137-.20135-.52785 0-.72922Z' fill='currentColor'/%3E%3C/svg%3E");
        }
        &:focus {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' viewBox='0 0 7 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.62297 4.640213 4.79833.464873c.20138-.20137.52785-.20137.7292 0l.486991.48699c.20103.20103.201416.52684.00086.72834l-3.30905 3.32462 3.30905 3.3246c.200557.2015.20017.52731-.00086.728342l-.48698.486987c-.20138.201373-.52785.201373-.72921 0L.62297 5.369432c-.20135-.20137-.20135-.52785 0-.72922Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
          }
        }
      }

      &.app-header__small-screen-nav-panel-search-button {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'%3E%3Cpath d='M8.709085 7.865302l-.037186.047567h.444242c.143029 0 .27916.055852.37954.156232l2.347854 2.347854.000109.000108c.20749.209889.20749.549217-.002363.75907l-.666466.666454-.000109.00012c-.20744.209684-.546672.209804-.75661-.00012L8.070135 9.494648c-.10038-.10038-.156231-.236512-.156231-.37954v-.444243l-.047568.037186c-.826315.646077-1.865606 1.02997-2.996294 1.02997-2.689665 0-4.869008-2.179347-4.869008-4.869013S2.180376 0 4.870041 0c2.689666 0 4.869001 2.179342 4.869001 4.869008 0 1.130688-.38388 2.169979-1.029957 2.996294zM1.82618 4.869008c0 1.68363 1.362618 3.043861 3.043861 3.043861 1.683631 0 3.043862-1.362618 3.043862-3.04386 0-1.683631-1.362618-3.043862-3.043862-3.043862-1.68363 0-3.04386 1.362618-3.04386 3.043861z' fill='%2328262E' fill-rule='nonzero'%3E%3C/path%3E%3C/svg%3E");
        }
        &:focus {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'%3E%3Cpath d='M8.709085 7.865302l-.037186.047567h.444242c.143029 0 .27916.055852.37954.156232l2.347854 2.347854.000109.000108c.20749.209889.20749.549217-.002363.75907l-.666466.666454-.000109.00012c-.20744.209684-.546672.209804-.75661-.00012L8.070135 9.494648c-.10038-.10038-.156231-.236512-.156231-.37954v-.444243l-.047568.037186c-.826315.646077-1.865606 1.02997-2.996294 1.02997-2.689665 0-4.869008-2.179347-4.869008-4.869013S2.180376 0 4.870041 0c2.689666 0 4.869001 2.179342 4.869001 4.869008 0 1.130688-.38388 2.169979-1.029957 2.996294zM1.82618 4.869008c0 1.68363 1.362618 3.043861 3.043861 3.043861 1.683631 0 3.043862-1.362618 3.043862-3.04386 0-1.683631-1.362618-3.043862-3.043862-3.043862-1.68363 0-3.04386 1.362618-3.04386 3.043861z' fill='%23FFFFFF' fill-rule='nonzero'%3E%3C/path%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .app-header__small-screen-nav-panel-top {
    border-bottom: 1px solid map_get($app-colors, "neutral--400");
    // bottom: 90px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    // left: 0;
    overflow-y: auto;
    // position: absolute;
    position: relative;
    // top: 80px;
    width: 100%;

    & > :first-child {
      align-items: flex-start;
    }
    & > :last-child {
      align-items: flex-end;
      padding-bottom: $spacer * 2;
    }
  }

  .app-header__utility-nav-wrapper {
    border-bottom: 0;
    display: block;
    padding: 0;
  }

  .app-header__utility-nav-list {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: $spacer;

    .app-header__utility-nav-item {
      margin-bottom: 0;
      padding: 0;
      a,
      button {
        background-color: transparent;
        border: 0;
        color: map_get($app-colors, "neutral--850");
        display: block;
        font-size: 0.9rem;
        font-weight: normal;
        margin: 0;
        padding: ($spacer * 0.25) 0;
        text-align: left;
        text-decoration: none;
        text-transform: none;
        width: 100%;
      }

      &:first-child {
        > button.has-icon-search {
          display: none;
        }
      }

      &:last-child {
        display: none;
      }
    }
  }

  .app-header__global-search-panel {
    background-color: map_get($app-colors, "neutral--200");
    // opacity: .4;

    .app-header__global-search-panel-close-button {
      display: none !important;
    }

    .app-header__global-search-panel-inner {
      padding: ($spacer * 5) $spacer;
    }

    .app-header__global-search-panel-logo {
      display: none;
    }

    .app-header__global-search-panel-header {
      font-size: 1rem;
      margin-bottom: $spacer;
      text-align: left;
    }
    .app-header__global-search-input-wrapper {
      margin-right: 0;
      margin-bottom: $spacer * 0.5;
      width: 100%;

      label {
        background-position: 10px 50%;
        background-size: 10px;
        font-size: 0.9rem;
        padding-left: $spacer * 1.85;
        padding-right: $spacer * 0.5;
      }

      input[type="text"] {
        font-size: 1rem;
        padding: ($spacer * 0.45) ($spacer * 0.675);
      }
    }
    .app-header__global-search-form {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .app-header__primary-nav-wrapper {
    height: initial;
  }

  .app-header__primary-nav {
    display: block;
  }

  .app-header__primary-nav-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: $spacer;

    > .app-header__primary-nav-item {
      margin-bottom: 0;
      position: initial;
      padding: 0;

      > button {
        background-color: transparent;
        border-bottom: 3px solid transparent;
        color: map_get($app-colors, "neutral--850");
        display: block;
        font-size: 1.15rem;
        font-weight: 500;
        margin: 0;
        padding: ($spacer * 0.25) 0;
        position: relative;
        text-align: left;
        text-decoration: none;
        text-transform: none;
        width: 100%;

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00449 5.36455 1.82913 9.53989c-.20138.20137-.52785.20137-.7292 0L.612939 9.0529c-.201029-.20103-.201416-.52684-.00086-.72834L3.92113 4.99994.612079 1.67534c-.200557-.2015-.20017-.52731.00086-.728342L1.09992.460011c.20138-.201373.52785-.201373.72921 0L6.00449 4.63533c.20135.20137.20135.52785 0 .72922Z' fill='#{encode_color( map_get($app-colors, 'neutral--900') )}'/%3E%3C/svg%3E");
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 9px;
          content: "";
          height: 16px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 50px;
        }

        &:hover {
          text-decoration: none;
        }

        &:focus {
          background-color: map_get($app-colors, "blue--dark");
          border-color: map_get($app-colors, "blue--dark");
          box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100"),
            0 0 0 6px map_get($app-colors, "blue--dark");
          color: map_get($app-colors, "neutral--100");
          padding-left: $spacer * 0.5;

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00449 5.36455 1.82913 9.53989c-.20138.20137-.52785.20137-.7292 0L.612939 9.0529c-.201029-.20103-.201416-.52684-.00086-.72834L3.92113 4.99994.612079 1.67534c-.200557-.2015-.20017-.52731.00086-.728342L1.09992.460011c.20138-.201373.52785-.201373.72921 0L6.00449 4.63533c.20135.20137.20135.52785 0 .72922Z' fill='#{encode_color( map_get($app-colors, 'neutral--100') )}'/%3E%3C/svg%3E");
          }
        }
      }

      &.is-open {
        .app-header__primary-nav-item-panel {
          left: 0;
          overflow-x: hidden;
          right: 0;

          // .app-header__primary-nav-item-panel-header {
          //   background-color: map_get($app-colors, 'neutral--200');
          //   position: fixed;
          //   left: 0;
          //   top: 0;
          //   width: 100%;
          // }
        }
      }
    }
  }

  .app-header__primary-nav-item-panel {
    background-color: map_get($app-colors, "neutral--200");
    // border-bottom: 1px solid map_get($app-colors, "neutral--400");
    border-bottom: 0;
    bottom: 0;
    left: -100%;
    overflow-y: auto;
    padding-bottom: 40px;
    padding-top: 20px;
    position: absolute;
    transition: left 0.25s;
    top: 0;
    width: 100%;
    z-index: 2;

    .app-header__primary-nav-item-panel-content {
      .app-header__primary-nav-item-panel-content-column {
        // flex-direction: column;

        // @include media-breakpoint-up(sm) {
          // flex-direction: row;
        // }

        ul {
          display: flex;
          flex-direction: column;
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            border-bottom: 1px solid map_get($app-colors, "neutral--400");
            margin-bottom: 0;
            padding: 0;

            a {
              display: block;
              padding-bottom: $spacer * 0.5;
              padding-top: $spacer * 0.5;
            }
          }
        }

        a {
          color: map_get($app-colors, "neutral--800");
          display: inline-block;
          font-size: 0.85rem;
          padding-left: $spacer;
          text-decoration: none;
        }

        .app-header__primary-nav-item-panel-overview-link-wrapper {
          border-bottom: 1px solid map_get($app-colors, "neutral--400");
          padding-left: $spacer;

          a {
            border-radius: 0.15rem;
            font-weight: 600;
            margin-bottom: $spacer * 0.75;
            padding-left: 0;
            position: relative;

            &:focus {
              border-color: map_get($app-colors, "blue--dark");
              box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100"),
                0 0 0 6px map_get($app-colors, "blue--dark");
              outline: 2px solid transparent;
              outline-offset: 2px;
            }

            &::after {
              background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.67693.736776.54503-.559194c.23078-.236776.60395-.236776.83228 0L10.8269 5.07179c.2308.23677.2308.61965 0 .8539L6.05424 10.8224c-.23078.2368-.60396.2368-.83228 0l-.54503-.5592c-.23323-.2393-.22832-.6297.00982-.86396l2.95838-2.89168H.58922C.262694 6.50756 0 6.23804 0 5.90302v-.80604c0-.33502.262694-.60454.58922-.60454h7.05591L4.68675 1.60076c-.24059-.23426-.2455-.624689-.00982-.863984Z' fill='%235162F4'/%3E%3C/svg%3E");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 11px;
              content: "";
              height: 11px;
              position: absolute;
              right: -20px;
              top: 50%;
              transform: translateY(-50%);
              width: 11px;
            }
          }
        }


        .app-header__primary-nav-item-panel-overview-link-columns-wrapper {
          .row {
            flex-direction: column;
          }
        }

        .app-header__primary-nav-item-panel-link-list-header {
          display: inline-block;
          font-size: 0.75rem;
          text-transform: uppercase;
          font-weight: 600;
          margin-top: $spacer * 1.5;
          padding-left: $spacer;
          position: relative;

          &::after {
            background-color: map_get($app-colors, "orange");
            content: "";
            display: inline-block;
            height: 2px;
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
          }
        }

        .app-header__primary-nav-item-panel-link-list {
          margin-bottom: $spacer * 1.5;
        }
      }
    }
    // Overrides for when featured content exists
    .app-header__primary-nav-item-panel-content.has-featured {
      .app-header__primary-nav-item-panel-content-column-wrapper {
        &:first-child {
          // justify-content: flex-end;
        }
        &:last-child {
          // background-color: #f9f9ff;
          background-color: map_get($app-colors, "purple--pale");

          .app-header__primary-nav-item-panel-content-column {
            align-items: center;
            display: flex;
            padding: 0;

            > div {
              flex: 1 0 0%;
              min-width: 100%;

              @include media-breakpoint-up(sm) {
                min-width: 50%;
              }

              &:first-child {
                padding: $spacer;
              }

              &:last-child {
                display: none;

                @include media-breakpoint-up(sm) {
                  display: initial;
                }
              }

            }

            .app-header__primary-nav-item-panel-content-featured-header {
              margin-bottom: $spacer * 0.75;
              a {
                color: map_get($app-colors, "neutral--850");
                font-size: 1.2rem;
                padding: 0;
                text-decoration: none;
              }
            }
            .app-header__primary-nav-item-panel-content-featured-description {
              font-size: 0.9rem;
              line-height: 1.5;
            }
            .app-header__primary-nav-item-panel-content-featured-image-wrapper {
              position: relative;
              padding-bottom: 40%;

              img {
                filter: grayscale(100%) contrast(125%);
                object-fit: cover;
                position: absolute;
                height: 100%;
                width: 100%;
              }
              &::after {
                background-color: #333986;
                mix-blend-mode: screen;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                transition: opacity 0.25s;
              }
            }
          }
        }
      }
    }
  }

  .app-header__primary-nav-cta {
    // bottom: 0;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    // height: 86px;
    // left: 0;
    padding: $spacer;
    padding-bottom: 0;
    // position: absolute;
    width: 100%;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      justify-content: space-between;
    }

    .btn {
      margin-bottom: $spacer;
      max-width: 100%;
      min-width: 100%;

      @include media-breakpoint-up(sm) {
        max-width: 49%;
        min-width: 49%;
      }
    }
  }
}


// ================================================================================================
// Adjustments to allow scrolling of sidenav contents on small screens
/*
   When viewing a page with a sidenav on small screens (md and below), the sidenav is injected into
   app-header and becomes an accordion. The app-header's position is fixed on mobile, which causes
   an issue where a long list of sidenav items are not accessible when the accordion is open (they
   appear but fall off bottom of screen and you could not scroll to them. This fixes that.
*/
body.content-has-sidebar {
  @include media-breakpoint-down(md) {
    .app-header.has-open-sidenav {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;
      .app-header__small-screen-sidenav-panel {
        flex: 1;
        display: flex;
        flex-direction: column;
        .app-subpage-content__side-nav {
          flex: 1;
          display: flex;
          flex-direction: column;
          ul {
            overflow-y: auto;
            flex: 1;
            max-height: 76vh;
            padding-bottom: 2rem;
          }
        }
      }
    }
  }
}
