.search {

}

.search__banner {
  @include global-component;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ($spacer * 2.5) ($spacer * 1.25);
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: $spacer * 2.5;
  }

  @include media-breakpoint-up(lg) {
    padding: $spacer * 3.75;
  }
}

.search__banner-header {
  margin-bottom: $spacer * 1;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 2.5;
  }
}

.search__banner-form {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }

  a.btn,
  button.btn,
  button {
    font-size: 0.75rem;
    padding: 1rem 2.3rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.search__banner-keyword-input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  border: 0.5px solid black;

  @include media-breakpoint-down(sm) {
    margin-bottom: $spacer * 1.25;
    border: none;
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    background-color: map_get($app-colors, "neutral--100");
    flex-direction: row;
    margin-right: $spacer;
  }
}

.search__banner-keyword-label {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--900') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--100') )}' stroke-width='.049'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0 50%;
  color: map_get($app-colors, "neutral--700");
  font-size:18px;
  font-weight: 600;
  margin-bottom: $spacer * 1.25;
  padding-left: $spacer * 1.563;

  @include media-breakpoint-up(sm) {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--900') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--850') )}' stroke-width='.049'/%3E%3C/svg%3E");
    background-position: 18px 50%;
    border-right: 1px solid map_get($app-colors, "neutral--850");
    color: map_get($app-colors, "neutral--850");
    margin-bottom: 0;
    padding-left: $spacer * 2.75;
    padding-right: $spacer * 0.625;
  }
}

.search__banner-keyword-input {
  border: 0.5px solid map_get($app-colors, "neutral--900");
  flex: 1;
  margin-bottom: $spacer * 1.25;
  padding: 0.675rem;
  width: 100%;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    border: 0;
    width: initial;
  }
}

.search__no-results-message {
  font-size: 1.4rem;
  font-weight: 600;
  padding: ($spacer * 6) 0;
}

.search__toolbar {
  display: flex;
  flex-direction: column;
  font-size: .9rem;
  font-weight: 600;
  justify-content: space-between;
  padding: ($spacer * 2) $spacer;

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }
}

.search__currently-viewing-message {
  margin-right: $spacer;
  margin-bottom: $spacer * .5;
  text-transform: uppercase;

  @media screen and (min-width: 448px) and (max-width:575px) {
    margin-top: $spacer * -.5
  }

  @media screen and (max-width: 447px) {
    margin-top: $spacer * .5;
  }
}


.search__sort-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: $spacer * .5;
  margin-top: $spacer;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  label {
    font-weight: 700;
    margin-right: $spacer * .5;
    text-transform: uppercase;
    white-space: nowrap;
  }

  select {
    background-position: right 0 center;
    border: 0;
    border-bottom: 1px solid map_get($app-colors, "blue");
    background-color: transparent;
    cursor: pointer;
    font-size: 0.95rem;
    padding: 0;
    padding-right: $spacer * 1.25;
    width: auto;
  }
}


.search__results {
  min-height: 300px;
  position: relative;

  .loader {
    background-color: transparent;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;

    p {
      padding-top: $spacer;
    }
  }
  .loader__inner {
    align-items: center;
    background-color: map-get($app-colors, "neutral--100");
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: $spacer ($spacer * 1.5);
  }
  .loader__spinner {
    margin-bottom: 0;
  }

}

.search__no-results-message {

}





// ============================================================================================
// Template-specific overrides: Events
.search.search--events {
  @include media-breakpoint-up(lg) {
    // margin-top: $spacer * -4;
  }

  .search__banner {
    background-color: map-get($app-colors, "blue");
    color: map-get($app-colors, "neutral--100");
  }

  .search__banner-keyword-label {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--100') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--100') )}' stroke-width='.049'/%3E%3C/svg%3E");
    color: map-get($app-colors, "neutral--100");

    @include media-breakpoint-up(sm) {
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--900') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--100') )}' stroke-width='.049'/%3E%3C/svg%3E");
      color: map-get($app-colors, "neutral--900");
    }
  }

  .routing {
    @include media-breakpoint-up(xl) {
      padding-left: $spacer * 7;
      padding-right: $spacer * 7;
    }
  }
  .routing__card {
    margin-bottom: 0;
    padding-bottom: $spacer * 2;

    &:last-child {
      padding-bottom: 0;
    }
  }
  .routing__card-main {
    padding-bottom: $spacer * 2;
    position: relative;

    > .row > div:last-child > div {
      padding: $spacer * 1.25;
      @include media-breakpoint-up(lg) {
        padding: 0;
        padding-left: $spacer;
      }
    }
  }
  .routing__card-title {
    @include media-breakpoint-up(lg) {
      font-size: 1.35rem;
      padding-right: 160px;
      line-height: 1.5;
    }
  }
  .routing__card-date {
    font-weight: 600;
    text-transform: none;
    span {
      margin-right: $spacer * .5;
      padding-right: $spacer * .6;
      border-right: 1px solid map_get($app-colors, "neutral--800");

      &:last-child {
        border: 0;
        margin-right: 0;
        padding-right: 0;

      }
    }
  }
  .routing__card-description {
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      font-size: 1.15rem;
    }
  }
  .routing__card-member-only-badge {
    margin-top: $spacer;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .routing__card-footer {
    color: map_get($app-colors, "neutral--700");
    font-weight: 600;
    @include media-breakpoint-up(lg) {
      margin-top: $spacer * .5;
    }
  }
  .routing__card-image-wrapper {
    margin-bottom: $spacer * 1.25;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .pagination {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}


// ============================================================================================
// Template-specific overrides: Global
.search.search--global {
  .search__banner {
    background-color: map-get($app-colors, "orange--pale");
    color: black;
  }

  .search__toolbar-wrapper {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid map-get($app-colors, "neutral--400");
      padding-left: $spacer;
      padding-right: $spacer;
    }

    @include media-breakpoint-up(xl) {
      padding-left: $spacer * 2.5;
      padding-right: $spacer * 2.5;
    }
  }

  .search__toolbar {
    max-width: 1470px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      padding-left: $spacer * 4;
      padding-right: $spacer * 4;
    }

    @include media-breakpoint-up(xl) {
      padding-left: $spacer * 7;
      padding-right: $spacer * 7;
    }
  }

  .routing {
    @include media-breakpoint-up(xl) {
      padding-left: $spacer * 7;
      padding-right: $spacer * 7;
    }
  }

  .routing__card {
    margin-bottom: 0;
    padding-bottom: $spacer * 2;

    &:last-child {
      padding-bottom: 0;
    }
  }
  .routing__card-main {
    padding-bottom: $spacer * 2;
  }
  .routing__card-title {
    @include media-breakpoint-up(lg) {
      font-size: 1.35rem;
      line-height: 1.5;
    }
  }
  .routing__card-description {
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      font-size: 1.15rem;
    }
  }
  .routing__card-image-wrapper {
    margin-top: $spacer;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .pagination {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}


// ============================================================================================
// Template-specific overrides: News
.search.search--news {
  @include media-breakpoint-up(lg) {
    margin-top: $spacer * -3;
  }

  .search__banner {
    background-color: map-get($app-colors, "orange");
    color: map-get($app-colors, "neutral--900");
  }
  // ------------------------------------------------------------------------------------------
  // Embedded Component Overrides
  .routing {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    .routing__card-main {
      background-color: map-get($app-colors, "neutral--100");
      border-bottom: 0;
    }
    .routing__card-eyebrow {
      padding: 0 $spacer;
    }
    .routing__card-title {
      padding: 0 $spacer;
    }
    .routing__card-date {
      padding: 0 $spacer;
    }
    .routing__card-member-only-badge {
      margin: 0 $spacer ($spacer * .5) $spacer;
    }
  }
}


// ============================================================================================
// Template-specific overrides: Resources
.search.search--resources {

  @include media-breakpoint-up(lg) {
    margin-top: $spacer * -5;
  }

  > section {
    position: relative;

    &:before {
      background-color: map-get($app-colors, "orange--pale");
      content:'';
      height: 200px;
      position: absolute;
      left: 0;
      right: 0;
    }

    .app-main__content {
      position: relative;
    }
  }

  .search__banner {
    background-color: map-get($app-colors, "purple");
    color: map-get($app-colors, "neutral--100");
  }

  .search__banner-keyword-label {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--100') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--100') )}' stroke-width='.049'/%3E%3C/svg%3E");
    color: map-get($app-colors, "neutral--100");

    @include media-breakpoint-up(sm) {
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 10 11' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.246 7.047-.03.04h.368c.119 0 .232.046.315.13l1.947 1.947a.447.447 0 0 1-.002.63l-.553.552a.442.442 0 0 1-.627 0L6.716 8.399a.444.444 0 0 1-.13-.315v-.368l-.039.03A4.037 4.037 0 1 1 4.062.524a4.037 4.037 0 0 1 3.184 6.523ZM1.538 4.563a2.523 2.523 0 0 0 2.524 2.524 2.523 2.523 0 0 0 2.525-2.524 2.523 2.523 0 0 0-2.524-2.525 2.523 2.523 0 0 0-2.525 2.525Z' fill='#{encode_color( map_get($app-colors, 'neutral--900') )}' stroke='#{encode_color( map_get($app-colors, 'neutral--100') )}' stroke-width='.049'/%3E%3C/svg%3E");
      color: map-get($app-colors, "neutral--900");
    }
  }

  .search__banner-form {
    .btn {
      border-color: #fff;
    }
  }
  // ------------------------------------------------------------------------------------------
  // Embedded Component Overrides
  .routing {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    .routing__card-main {
      background-color: map-get($app-colors, "neutral--100");
      border-bottom: 0;
    }
    .routing__card-eyebrow {
      padding: 0 $spacer;
    }
    .routing__card-title {
      padding: 0 $spacer;
    }
    .routing__card-date {
      padding: 0 $spacer;
    }
    .routing__card-member-only-badge {
      margin: 0 $spacer ($spacer * .5) $spacer;
    }
  }
}

