.app-subpage-content {
  .app-main__content {
    padding: 0;
  }

  // ============================================================================================
  // Embedded Component Overrides
  .basic-image {
    .basic-image__image-wrapper {
      img {
        @include media-breakpoint-up(lg) {
          max-width: 100%;
        }
      }
    }
  }

  .app-subpage-content__summary {
    background-color: map-get($app-colors, "neutral--200");
    border: $spacer solid map-get($app-colors, "neutral--100");
    line-height: 1.4;
    margin-bottom: $spacer * 0.5;

    @include media-breakpoint-up(lg) {
      border: 0;
      margin-bottom: $spacer * 1.5;
      padding-bottom: $spacer * 0.5;
      padding-top: $spacer * 0.5;
    }

    // ============================================================================================
    // Embedded Component Overrides
    .rich-text {
      background-color: transparent;
      p {
        // font-size: 1.15rem;
        // line-height: 1.6;

        @include media-breakpoint-up(md) {
          // font-size: 1.4rem;
        }
      }
      li {
        // font-size: 1.15rem;
        // line-height: 1.6;
        margin-bottom: $spacer;

        @include media-breakpoint-up(md) {
          // font-size: 1.3rem;
        }
      }
    }
  }
}

.app-subpage-content__sidebar {
  @include media-breakpoint-up(lg) {
    padding-right: $spacer * 3;
  }

  &.has-nav {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.app-subpage-content__sidebar-member-exclusive {
  background: linear-gradient(
    90deg,
    map-get($app-colors, "orange") 56.99%,
    map-get($app-colors, "gold") 147.71%
  );
  padding: $spacer;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;

  @include media-breakpoint-up(md) {
    padding: $spacer ($spacer * 3);
  }

  @include media-breakpoint-up(lg) {
    padding: $spacer;
    margin-bottom: ($spacer * 3.125);
  }

  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 4;
  }
}

.app-subpage-content__sidebar-content-wrapper {
  background-color: map-get($app-colors, "neutral--200");
  padding: ($spacer * 2) $spacer;

  @include media-breakpoint-up(md) {
    padding: $spacer * 3;
  }

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

.app-subpage-content__sidebar-header > div,
.app-subpage-content__sidebar-header > button {
  background-color: transparent;
  border-bottom: 1px solid map-get($app-colors, "neutral--400");
  font-size: 0.85rem;
  font-weight: 700;
  padding: 0;
  text-align: left;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    padding-left: $spacer;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.1rem;
    padding-left: $spacer * 4;
  }
}

.app-subpage-content__sidebar-content {
  @include media-breakpoint-up(lg) {
    padding-left: $spacer;
    padding-top: $spacer;
  }
  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 4;
  }
}

.app-subpage-content__sidebar-published {
  background-color: map-get($app-colors, "neutral--200");
  border-bottom: 1px solid map-get($app-colors, "neutral--400");
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacer * 0.5;
  margin-bottom: $spacer * 0.5;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    border-bottom: 0;
    flex-direction: column;
    margin-bottom: $spacer * 2.5;
  }

  .app-subpage-content__sidebar-header > div {
    border-bottom: 0;

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid map-get($app-colors, "neutral--400");
      padding-bottom: $spacer * 0.75;
    }
  }

  .app-subpage-content__sidebar-publish-date {
    font-size: 0.9rem;
    text-transform: uppercase;
  }
}

.app-subpage-content__sidebar-accordion {
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer * 2;
  }

  .app-subpage-content__sidebar-header > div,
  .app-subpage-content__sidebar-header > button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer * 0.5;
    padding-bottom: $spacer * 0.5;
    position: relative;
    width: 100%;
  }

  .app-subpage-content__sidebar-header > div {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      padding-bottom: $spacer * 0.75;
    }
  }

  .app-subpage-content__sidebar-header > button {
    display: flex;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.74 11.6061h-7.5784v7.9903H7.57837v-7.9903H0V8.43475h7.57837V.40332h3.58323v8.03143H18.74v3.17135Z' fill='%23606060'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      content: "";
      display: block;
      height: 16px;
      min-width: 16px;
      width: 16px;
    }

    &[aria-expanded="true"] {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='4' viewBox='0 0 19 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0v4h18.75V0H0Z' fill='%23606060'/%3E%3C/svg%3E");
      }
    }
  }

  .app-subpage-content__sidebar-content {
    display: none;
    padding: $spacer 0 ($spacer * 3) 0;

    @include media-breakpoint-up(lg) {
      display: block;
      padding-left: $spacer;
      padding-bottom: $spacer;
    }

    @include media-breakpoint-up(xl) {
      padding-left: $spacer * 4;
    }
  }
}

.app-subpage-content__sidebar-authors {
  color: map-get($app-colors, "neutral--700");
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.has-images {
    .app-subpage-content__sidebar-authors-item {
      line-height: 1.7;
      min-height: 70px;
      padding-left: 90px;

      @include media-breakpoint-up(lg) {
        padding-right: 20px;
      }

      img {
        border-radius: 100%;
        object-fit: cover;
        height: 70px;
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
      }
    }
  }
}
.app-subpage-content__sidebar-authors-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $spacer;
  position: relative;

  p {
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
}

.app-subpage-content__sidebar-tags {
  color: map-get($app-colors, "neutral--700");
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: $spacer;

    ul {
      display: inline;
      line-height: 1.5;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &:before {
        content: " ";
        margin-right: $spacer * 0.5;
      }

      li {
        display: inline-block;
        margin-bottom: 0;
        padding-right: $spacer;

        a {
          color: inherit;
          font-weight: 400;
          text-decoration: underline;
          white-space: pre-line;

          &:hover {
            text-decoration: none;
          }
        }
      }

      // Overrides for Industry & Topic tags
      &.app-subpage-content__sidebar-topic-tag-list,
      &.app-subpage-content__sidebar-industry-tag-list {
        li {
          display: block;
          margin-top: $spacer * 0.5;
          padding-right: 0;
        }
      }
    }
  }
}

.app-subpage-content__sidebar-cta-wrapper {
  margin-top: $spacer * 4;
  padding: 0 $spacer;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    padding: 0;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }
}

.app-subpage-content__side-nav-header {
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  position: relative;

  @include media-breakpoint-up(lg) {
    border-bottom: 2px solid map-get($app-colors, "neutral--900");
    margin-bottom: $spacer * 0.5;
  }

  a {
    background-color: map_get($app-colors, "blue");
    color: map_get($app-colors, "neutral--100");
    display: block;
    font-size: 0.85rem;
    height: 50px;
    max-height: 50px;
    overflow: hidden;
    padding: ($spacer * 0.75) $spacer;
    text-align: left;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      color: map_get($app-colors, "neutral--100");
      text-decoration: underline;
    }

    @include media-breakpoint-up(lg) {
      background-color: transparent;
      color: map-get($app-colors, "neutral--900");
      font-size: 0.9rem;
      height: initial;
      max-height: none;
      padding: ($spacer * 0.5) $spacer ($spacer * 0.5) $spacer;
      white-space: initial;

      &:hover {
        color: map-get($app-colors, "neutral--900");
        text-decoration: underline;
      }
    }

    @include media-breakpoint-up(xl) {
      font-size: 1rem;
      padding-left: $spacer * 4;
    }
  }

  > button {
    align-items: center;
    background-color: transparent;
    border-left: 1px solid transparent;
    bottom: 10px;
    display: flex;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 65px;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:before {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      content: "";
    }

    &:focus {
      // background-color: map_get($app-colors, "blue--pale");
      border-color: map_get($app-colors, "blue");
      box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100"),
        0 0 0 6px map_get($app-colors, "blue");
    }

    &[aria-expanded="true"] {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.063722 9.293311-6.41651-6.41654c-.309458-.309459-.309458-.811181 0-1.120609l.748388-.748384c.308936-.308934.809629-.309528 1.119287-.00132L7.62404 6.09168l5.109124-5.085223c.309658-.308207.810356-.307614 1.119291.001321l.748383.748384c.309462.309458.309462.81118 0 1.120608L8.18436 9.293311c-.309458.309428-.81118.309428-1.120639 0Z' fill='%23FFFFFF' fill-rule='nonzero'/%3E%3C/svg%3E");
        max-height: 10px;
        max-width: 15px;
        min-height: 10px;
        min-width: 15px;
      }
    }

    &[aria-expanded="false"] {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.766803 8.71084-6.41654 6.41651c-.309459.309458-.811182.309458-1.120609 0l-.748384-.748388c-.308934-.308936-.309529-.809629-.00132-1.119286L5.565171 8.15052.47995 3.041397C.17174 2.731739.172335 2.23104.48127 1.922106l.748384-.748383c.309458-.309462.81118-.309462 1.120608 0l6.416541 6.416478c.309427.309458.309427.811181 0 1.120639Z' fill='%23FFFFFF' fill-rule='nonzero'/%3E%3C/svg%3E");
        max-height: 16px;
        max-width: 9px;
        min-height: 16px;
        min-width: 9px;
      }
    }

    &:hover {
      &[aria-expanded="true"] {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.063722 9.293311-6.41651-6.41654c-.309458-.309459-.309458-.811181 0-1.120609l.748388-.748384c.308936-.308934.809629-.309528 1.119287-.00132L7.62404 6.09168l5.109124-5.085223c.309658-.308207.810356-.307614 1.119291.001321l.748383.748384c.309462.309458.309462.81118 0 1.120608L8.18436 9.293311c-.309458.309428-.81118.309428-1.120639 0Z' fill='%23E8EBFE' fill-rule='nonzero'/%3E%3C/svg%3E");
        }
      }
      &[aria-expanded="false"] {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.766803 8.71084-6.41654 6.41651c-.309459.309458-.811182.309458-1.120609 0l-.748384-.748388c-.308934-.308936-.309529-.809629-.00132-1.119286L5.565171 8.15052.47995 3.041397C.17174 2.731739.172335 2.23104.48127 1.922106l.748384-.748383c.309458-.309462.81118-.309462 1.120608 0l6.416541 6.416478c.309427.309458.309427.811181 0 1.120639Z' fill='%23E8EBFE' fill-rule='nonzero'/%3E%3C/svg%3E");
        }
      }
    }
  }
}

.app-subpage-content__side-nav {
  background-color: map_get($app-colors, "purple--pale");
  position: relative;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
  }

  > ul {
    font-size: 0.9rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1rem;
    }

    &.is-open {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 75px;

      > ul {
        display: block;
      }
    }

    > li {
      > div {
        position: relative;

        > a {
          border-bottom: 1px solid map-get($app-colors, "neutral--400");
          color: map-get($app-colors, "neutral--850");
          display: block;
          flex: 1;
          line-height: 1.75;
          padding: $spacer 100px $spacer ($spacer * 2);
          text-decoration: none;

          @include media-breakpoint-up(lg) {
            padding-left: $spacer;
          }

          @include media-breakpoint-up(xl) {
            padding-left: $spacer * 4;
          }

          &:hover {
            color: map-get($app-colors, "blue");
          }
        }

        > button {
          align-items: center;
          background-color: transparent;
          border-left: 1px solid map-get($app-colors, "neutral--400");
          bottom: 10px;
          display: flex;
          justify-content: center;
          padding: 0;
          position: absolute;
          right: 10px;
          top: 10px;
          width: 65px;

          &:before {
            background-repeat: no-repeat;
            background-position: 50% 50%;
            content: "";
          }

          &:focus {
            background-color: map_get($app-colors, "blue--pale");
            border-color: map_get($app-colors, "blue");
            box-shadow: 0 0 0 3px map_get($app-colors, "neutral--100"),
              0 0 0 6px map_get($app-colors, "blue");
          }

          &[aria-expanded="true"] {
            &:before {
              background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.063722 9.293311-6.41651-6.41654c-.309458-.309459-.309458-.811181 0-1.120609l.748388-.748384c.308936-.308934.809629-.309528 1.119287-.00132L7.62404 6.09168l5.109124-5.085223c.309658-.308207.810356-.307614 1.119291.001321l.748383.748384c.309462.309458.309462.81118 0 1.120608L8.18436 9.293311c-.309458.309428-.81118.309428-1.120639 0Z' fill='%2328262E' fill-rule='nonzero'/%3E%3C/svg%3E");
              max-height: 10px;
              max-width: 15px;
              min-height: 10px;
              min-width: 15px;
            }
          }

          &[aria-expanded="false"] {
            &:before {
              background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.766803 8.71084-6.41654 6.41651c-.309459.309458-.811182.309458-1.120609 0l-.748384-.748388c-.308934-.308936-.309529-.809629-.00132-1.119286L5.565171 8.15052.47995 3.041397C.17174 2.731739.172335 2.23104.48127 1.922106l.748384-.748383c.309458-.309462.81118-.309462 1.120608 0l6.416541 6.416478c.309427.309458.309427.811181 0 1.120639Z' fill='%2328262E' fill-rule='nonzero'/%3E%3C/svg%3E");
              max-height: 16px;
              max-width: 9px;
              min-height: 16px;
              min-width: 9px;
            }
          }

          &:hover {
            &[aria-expanded="true"] {
              &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7.063722 9.293311-6.41651-6.41654c-.309458-.309459-.309458-.811181 0-1.120609l.748388-.748384c.308936-.308934.809629-.309528 1.119287-.00132L7.62404 6.09168l5.109124-5.085223c.309658-.308207.810356-.307614 1.119291.001321l.748383.748384c.309462.309458.309462.81118 0 1.120608L8.18436 9.293311c-.309458.309428-.81118.309428-1.120639 0Z' fill='%235162f4' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
            }
            &[aria-expanded="false"] {
              &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.766803 8.71084-6.41654 6.41651c-.309459.309458-.811182.309458-1.120609 0l-.748384-.748388c-.308934-.308936-.309529-.809629-.00132-1.119286L5.565171 8.15052.47995 3.041397C.17174 2.731739.172335 2.23104.48127 1.922106l.748384-.748383c.309458-.309462.81118-.309462 1.120608 0l6.416541 6.416478c.309427.309458.309427.811181 0 1.120639Z' fill='%235162f4' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
            }
          }
        }
      }

      > ul {
        list-style-type: none;
        min-width: 100%;
        margin: 0;
        padding: 0;

        &[aria-hidden="true"] {
          display: none;
        }

        &[aria-hidden="false"] {
          display: block;
        }

        > li {
          > a {
            border-bottom: 1px solid map-get($app-colors, "neutral--400");
            color: map-get($app-colors, "neutral--800");
            display: block;
            font-size: 0.85rem;
            line-height: 1.75;
            padding: ($spacer * 0.5) 0 ($spacer * 0.5) ($spacer * 3.5);
            text-decoration: none;

            @include media-breakpoint-up(xl) {
              font-size: 0.9rem;
              padding: ($spacer * 0.75) 0 ($spacer * 0.75) ($spacer * 5);
            }

            &:hover {
              color: map-get($app-colors, "blue");
            }
          }
        }
      }
    }
  }

  .is-current {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.67693 1.23678.54503-.559198c.23078-.236776.60395-.236776.83228 0L10.8269 5.57179c.2308.23677.2308.61965 0 .8539L6.05424 11.3224c-.23078.2368-.60396.2368-.83228 0l-.54503-.5592c-.23323-.2393-.22832-.6297.00982-.86396l2.95838-2.89168H.58922C.262694 7.00756 0 6.73804 0 6.40302v-.80604c0-.33502.262694-.60454.58922-.60454h7.05591L4.68675 2.10076c-.24059-.23426-.2455-.62469-.00982-.86398Z' fill='%2328262E'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: ($spacer * 0.5) 50%;
    font-weight: 700;

    @include media-breakpoint-up(lg) {
      background-position: 0 50%;
    }

    @include media-breakpoint-up(xl) {
      background-position: $spacer 50%;
    }
  }
}

// ============================================================================
// Event Detail Overrides
.app-subpage-content.app-subpage-content--event {
  .app-subpage-content__sidebar {
    .app-subpage-content__sidebar-member-exclusive {
      margin-bottom: $spacer * 1.5;
    }

    .app-subpage-content__sidebar-content-wrapper {
      background-color: map_get($app-colors, "neutral--100");
      padding-top: 0;
      padding-bottom: $spacer;

      @include media-breakpoint-up(md) {
        padding-top: 0;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
    .app-subpage-content__sidebar-event-date {
    }

    .app-subpage-content__sidebar-event-date {
      font-size: 1.1rem;
      font-weight: 700;
      margin-bottom: $spacer * 0.25;
      margin-top: 1rem;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    .app-subpage-content__sidebar-event-additional-info {
      margin-bottom: $spacer * 0.25;
    }

    .app-subpage-content__sidebar-event-location {
      margin-bottom: $spacer * 0.25;
    }

    .app-subpage-content__sidebar-event-format {
      margin-bottom: $spacer * 0.25;
    }

    .app-subpage-content__sidebar-event-info-link-wrapper {
      margin-bottom: $spacer * 1.75;
    }
  }

  .app-subpage-content__summary {
    background-color: map_get($app-colors, "blue--pale");
  }
}

// Complex Event layout
.app-subpage-content--complex-event {
  max-width: 1140px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    width: 100vw;
  }

  @include media-breakpoint-down(lg) {
    margin: 0;
  }

  .app-subpage-content__summary {
    padding: 0;
    background-color: transparent;
  }

  .rich-text {
    padding-left: 0.25rem;
    padding-right: 0;
  }

  .complex-event-details {
    .rich-text {
      padding: 0;

      p {
        @include media-breakpoint-down(sm) {
          font-size: 1.38rem;
        }
      }
    }
  }

  .complex-event-details__info {
    display: flex;
    font-size: 1.125rem;
    margin-bottom: 2.69rem;

    p {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      font-size: 1rem;
      margin-bottom: 1.38rem;
    }
  }

  .complex-event-details__info-time {
    border-right: 1px solid #000;
    padding-right: 29px;
    margin-right: 29px;

    @include media-breakpoint-down(sm) {
      border-right: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 0.5rem;
      border-bottom: 1px solid #000;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }

  .complex-event-details__info-date {
    font-weight: 600;
  }

  .headshot {
    padding-left: 0;
    padding-right: 0;
  }
}
