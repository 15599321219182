@import "./variables";
@import "./utilities";
@import "./functions";

/* Bootstrap Core Functions
=========================================================================== */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

/* Bootstrap Core Utilities
=========================================================================== */
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";

/* Bootstrap Core Layout
=========================================================================== */
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/type";

/* Bootstrap Components
   Only uncomment what you need to reduce css payload size
=========================================================================== */
// @import "../node_modules/bootstrap/scss/accordion";
// @import "../node_modules/bootstrap/scss/alert";
// @import "../node_modules/bootstrap/scss/badge";
// @import "../node_modules/bootstrap/scss/breadcrumb";
// @import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/card";
// @import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/close";
// @import "../node_modules/bootstrap/scss/dropdown";
// @import "../node_modules/bootstrap/scss/forms";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/modal";
// @import "../node_modules/bootstrap/scss/nav";
// @import "../node_modules/bootstrap/scss/navbar";
// @import "../node_modules/bootstrap/scss/pagination";
// @import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/progress";
// @import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/tables";
// @import "../node_modules/bootstrap/scss/toasts";
// @import "../node_modules/bootstrap/scss/tooltip";
// @import "../node_modules/bootstrap/scss/transitions";


/* Hamburgers
=========================================================================== */
// $hamburger-types: (collapse); // Only pull the style we are using
// @import "../node_modules/hamburgers/_sass/hamburgers/hamburgers";
/* Using local version from vendors directory below instead of npm version above
   so we can fix a sass issue (use line above instead and view build output for more)
*/
// @import "../vendor/hamburgers/_sass/hamburgers/hamburgers";

body.debug {
  // Enable breakpoint indicators for debugging
  &:before {
    background-color: red;
    border-top-left-radius: 5px;
    bottom: 0;
    box-shadow: 0 0 3px 3px rgba(0,0,0, .2);
    color: #ffffff;
    content: '< SM';
    font-size: 10px;
    width: 50px;
    padding: .5rem;
    position: fixed;
    right: 0;
    text-align: center;
    white-space: nowrap;
    z-index: 10000;

    @include media-breakpoint-up(sm) {
      content: 'SM';
      background-color: rgba(orange, .75);
      color: #000000;
    }
    @include media-breakpoint-up(md) {
      content: 'MD';
      background-color: rgba(yellow, .75);
      color: #000000;
    }
    @include media-breakpoint-up(lg) {
      content: 'LG';
      background-color: rgba(green, .75);
      color: #000000;
    }
    @include media-breakpoint-up(xl) {
      content: 'XL';
      background-color: rgba(blue, .75);
      color: #ffffff;
    }
    @include media-breakpoint-up(xxl) {
      content: 'XXL';
      background-color: rgba(purple, .75);
      color: #ffffff;
    }
  }
}

::selection {
  background: map_get($app-colors, "orange");
  color: map_get($app-colors, "neutral--850");
}

img {
  max-width: 100%;
}

@import "./mixins/globals";

// @import "./forms";
// @import "./themes";
// @import "./order-details";

@import "./app-header";
@import "./app-main";
@import "./app-footer";

@import "./animations";

@import "./azure";

// Components
@import "./components/accordion";
@import "./components/basic-image";
@import "./components/basic-text-block";
@import "./components/breadcrumbs";
@import "./components/cohort-directory";
@import "./components/cta-banner";
@import "./components/event-routing";
@import "./components/featured-content";
@import "./components/feedback-message";
@import "./components/form-builder";
@import "./components/gated-content-cta";
@import "./components/headshot";
@import "./components/hero-banner";
@import "./components/home-collage-cta";
@import "./components/home-hero-banner";
@import "./components/home-program-routing";
@import "./components/iframe";
@import "./components/impact-stats";
@import "./components/kentico-admin";
@import "./components/loader";
@import "./components/logo-zone";
@import "./components/magazine-rack";
@import "./components/modal";
@import "./components/newsletter-signup";
@import "./components/order-review-member-msg";
@import "./components/pagination";
@import "./components/pagination-react";
@import "./components/partner-profile";
@import "./components/portal-announcements";
@import "./components/portal-billing-and-payments";
@import "./components/portal-directory";
@import "./components/portal-featured-cta";
@import "./components/portal-membership-renewals";
@import "./components/portal-news";
@import "./components/portal-quicklinks";
@import "./components/portal-roster-activity-bar";
@import "./components/portal-resources";
@import "./components/portal-roster-management";
@import "./components/product-details";
@import "./components/rich-text";
@import "./components/routing";
@import "./components/tables";
@import "./components/video";
@import "./components/tab";

// Search UI
@import "./components/search";
@import "./components/search-filters";

// Elements
@import "./elements/headings";
@import "./elements/links";
@import "./elements/buttons";
@import "./elements/icons";
@import "./elements/horizontal-rule";
@import "./elements/layout-margins";

// Page Templates
@import "./page-templates/_portalpage.scss";
@import "./page-templates/_subpage.scss";
@import "./page-templates/_campaign-landing.scss";

// Print styles
@import "./print";
