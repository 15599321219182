.rich-text {
  @include global-component;

  > * {
    max-width: 900px;
  }

  > .row {
    > .col {
    }
    margin-bottom: $spacer;
  }

  h1,
  .like-h1 {
    font-size: 1.9rem;
    font-weight: 600;
    margin-bottom: $spacer;

    @include media-breakpoint-up(md) {
      font-size: 2.15rem;
    }

    strong {
      font-weight: 700;
    }
  }

  h2,
  .like-h2 {
    font-size: 1.525rem;
    font-weight: 600;
    margin-bottom: $spacer;

    @include media-breakpoint-up(md) {
      font-size: 1.65rem;
    }

    strong {
      font-weight: 700;
    }
  }
  p + h2,
  p + .like-h2,
  ul + h2,
  ul + .like-h2,
  ol + h2,
  ol + .like-h2,
  blockquote + h2,
  blockquote + .like-h2 {
    padding-top: $spacer;
  }

  h3,
  .like-h3 {
    font-size: 1.35rem;
    font-weight: 600;
    margin-bottom: $spacer;

    @include media-breakpoint-up(md) {
      font-size: 1.45rem;
    }

    strong {
      font-weight: 700;
    }
  }
  p + h3,
  p + .like-h3,
  ul + h3,
  ul + .like-h3,
  ol + h3,
  ol + .like-h3,
  blockquote + h3,
  blockquote + .like-h3 {
    padding-top: $spacer;
  }

  h4,
  .like-h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: $spacer * 0.75;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
    }

    strong {
      font-weight: 700;
    }
  }
  p + h4,
  p + .like-h4,
  ul + h4,
  ul + .like-h4,
  ol + h4,
  ol + .like-h4,
  blockquote + h4,
  blockquote + .like-h4 {
    padding-top: $spacer;
  }

  h5,
  .like-h5 {
    font-size: 1.05rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: $spacer * 0.75;

    strong {
      font-weight: 700;
    }
  }
  p + h5,
  p + .like-h5,
  ul + h5,
  ul + .like-h5,
  ol + h5,
  ol + .like-h5,
  blockquote + h5,
  blockquote + .like-h5 {
    padding-top: $spacer;
  }

  h6,
  .like-h6 {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: $spacer * 0.75;

    strong {
      font-weight: 700;
    }
  }
  p + h6,
  p + .like-h6,
  ul + h6,
  ul + .like-h6,
  ol + h6,
  ol + .like-h6,
  blockquote + h6,
  blockquote + .like-h6 {
    padding-top: $spacer;
  }

  p {
    font-size: 1rem;
    line-height: 1.9;

    @include media-breakpoint-up(md) {
      font-size: 1.1rem;
    }

    strong {
      font-weight: 700;
    }
  }

  li {
    font-size: 1rem;
    line-height: 1.7;
    margin-bottom: $spacer * 0.55;

    @include media-breakpoint-up(md) {
      font-size: 1.1rem;
    }

    strong {
      font-weight: 700;
    }
  }

  blockquote {
    background-color: map-get($app-colors, "neutral--200");
    border-left: 6px solid map-get($app-colors, "orange");
    padding: $spacer;

    @include media-breakpoint-up(md) {
      border-left: 10px solid map-get($app-colors, "orange");
      padding: $spacer * 2;
    }

    p {
      font-size: 1.15rem;
      font-weight: 600;
      line-height: 1.4;

      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }

      strong {
        font-weight: 700;
      }
    }
    cite {
      display: block;
      &:before {
        content: "— ";
      }
    }
  }
  p + blockquote,
  ul + blockquote,
  ol + blockquote {
    margin-top: $spacer * 2;
  }

  img {
  }

  em {
    font-style: italic;
  }


}
