.hero-banner {

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 0;
    padding-right: 0;
  }

}

.hero-banner__text-content-wrapper {
  @include global-component;
  flex: 1;

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }


  @include media-breakpoint-up(lg) {
    max-width: 80%;
    padding-bottom: $spacer * 3;
    padding-left: $spacer;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 4;
    padding-bottom: $spacer * 3;
    padding-top: $spacer * 3;
  }
}

.hero-banner__eyebrow {
  @include global-component__eyebrow--light;
}

.hero-banner__headline {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.3;

  &:after {
    content: "";
    display: block;
    height: 6px;
    background-color: map_get($app-colors, "orange");
    width: 100px;
    margin-top: $spacer;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.15rem;
  }
}

.hero-banner__description {
  font-size: 1rem;
  line-height: 1.5;
  max-width: none;
  padding-top: $spacer * 2;

  @include media-breakpoint-up(md) {
    font-size: 1.15em;
    line-height: 1.5;
  }

  a {
    color: map_get($app-colors, "blue");
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

  }
}

.hero-banner__cta {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: $spacer * 2;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .btn {
    margin-top: $spacer * 1.5;
    width: 100%;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer;
      margin-top: $spacer;
      width: auto;
    }
  }

  .btn-primary,
  a.btn.btn-primary,
  button.btn.btn-primary {
    background-color: map_get($app-colors, "orange");
    border-color: map_get($app-colors, "neutral--850");
    color: map_get($app-colors, "neutral--850");
    font-size: 1rem;
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin-right: $spacer * 1.5;
    }

    .external-link-icon {
      background-image: url("data:image/svg+xml,%3Csvg fill='#{encode_color( map_get($app-colors, 'neutral--900') )}' width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.4 1.4V.6c-.1-.3.2-.6.5-.6l6.8.1c.3 0 .6.3.6.6l.1 6.8c0 .3-.3.6-.6.6L10 8c-.3 0-.6-.2-.6-.6V3.3L4.7 8.1c-.2.2-.6.2-.8 0l-.6-.5c-.3-.3-.3-.7 0-.9l4.8-4.8L4 2c-.4 0-.6-.3-.6-.6Z'/%3E%3Cpath d='M2 2v7.3h9.4v1.1c0 .5-.4.9-.9.9H.6c-.1 0-.3-.1-.4-.1-.1-.2-.2-.3-.2-.5V.7C0 .6.1.4.1.3.3.1.4 0 .6 0H2v2Z'/%3E%3C/svg%3E") !important;
    }

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      background-color: map_get($app-colors, "neutral--850");
      border-color: map_get($app-colors, "neutral--850");
      color: map_get($app-colors, "neutral--100");
    }

    &:not(:disabled):not(.disabled):focus {
      color: map_get($app-colors, "neutral--100");
    }
  }


  .hero-banner__cta-pricing {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    padding-top: $spacer;
    // text-align: center;

    @include media-breakpoint-up(md) {
      // flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
      // border-left: 1px solid map_get($app-colors, "neutral--400");
      flex-direction: column;
      // padding-left: $spacer * 1.25;
      padding-top: 0;
      text-align: left;
    }

    .hero-banner__cta-price {
      font-size: 1.1rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    .hero-banner__cta-price-description {
      color: map_get($app-colors, "neutral--800");
      font-size: 1rem;
      margin-bottom: 0;
      // padding: 0 $spacer;

      @include media-breakpoint-up(lg) {
        // padding: 0;
      }
    }
  }

  &.has-button {
    .hero-banner__cta-pricing {
      @include media-breakpoint-up(lg) {
        border-left: 1px solid map_get($app-colors, "neutral--400");
        padding-left: $spacer * 1.25;
      }
    }
  }
}


// ============================================================================================
// Embedded Component Overrides
.hero-banner .logo-zone.is-inline {
  // @include global-component;

  @include media-breakpoint-up(lg) {
    max-width: 30%;
  }

  .logo-zone__headline {
    border-bottom: 1px solid map_get($app-colors, "neutral--400");
    font-size: 0.85rem;
    margin-bottom: 0;
    padding-bottom: $spacer * 0.5;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  .logo-zone__list {
    @include media-breakpoint-up(lg) {
      grid-gap: 10%;
      justify-content: flex-start;
    }
  }

  .logo-zone__list-item {
    border-color: rgba(0, 0, 0, 0.15);

    @include media-breakpoint-up(lg) {
      border-color: transparent;
    }
  }
}



// ============================================================================================
// Variant Overrides (Square)
.hero-banner.hero-banner--square {
  position: relative;
}

.hero-banner.hero-banner--square.has-image {

  @include media-breakpoint-up(lg) {
    flex-direction: column;
  }

  &:before {

    @include media-breakpoint-up(lg) {
      background-color: map_get($app-colors, "green");
      bottom: -($spacer * 2);
      content: "";
      height: 100%;
      max-width: 280px;
      position: absolute;
      right: -$spacer;
      width: 100%;
      z-index: -1;
    }
  }

  .hero-banner__text-content-wrapper {
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
  }

  .hero-banner__image {
    display: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40%;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  // ============================================================================================
  // Embedded Component Overrides
  .logo-zone.is-inline {
    @include media-breakpoint-up(lg) {
      max-width: 60%;
    }

    .logo-zone__list {
      @include media-breakpoint-up(lg) {
        margin: 0;
        grid-gap: 2rem;
      }
    }

    .logo-zone__list-item {
      @include media-breakpoint-up(lg) {
        // padding: 0 2rem 0 0;
      }

      img {
        @include media-breakpoint-up(lg) {
          margin: 0;
        }

    //     &.is-vertical {
    //       @include media-breakpoint-up(lg) {
    //         max-height: 65px;
    //       }
    //     }

    //     &.is-horizontal {
    //       @include media-breakpoint-up(lg) {
    //         max-height: 45px;
    //       }
    //     }

    //     &.is-horizontal--extreme {
    //       @include media-breakpoint-up(lg) {
    //         max-height: 35px;
    //       }
    //     }
      }
    }

  }
}



// ============================================================================================
// Variant Overrides (Wide)
.hero-banner.hero-banner--wide {
  position: relative;
}

.hero-banner.hero-banner--wide.has-image {
  .hero-banner__image {
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-top: $spacer * 2;
    }
  }
}



// ============================================================================================
// Variant Overrides (Collage)
.hero-banner.hero-banner--collage {

  // Adjust color themes
  @include media-breakpoint-up(lg) {
    align-items: center;
    padding-bottom: $spacer * 2;
    padding-top: $spacer * 2;

    &.t-pale-green {
      &:after {
        background-color: map_get($app-colors, 'orange--pale');
      }
      .hero-banner__image-wrapper {
        &:before {
          background-color: map_get($app-colors, 'green');
        }
      }
    }

    &.t-pale-orange {
      &:after {
        background-color: map_get($app-colors, 'blue--pale');
      }
      .hero-banner__image-wrapper {
        &:before {
          background-color: map_get($app-colors, 'orange');
        }
      }
    }

    &.t-pale-purple {
      &:after {
        background-color: map_get($app-colors, 'orange--pale');
      }
      .hero-banner__image-wrapper {
        &:before {
          background-color: map_get($app-colors, 'purple');
        }
      }
    }

    &.t-pale-blue {
      &:after {
        background-color: map_get($app-colors, 'green--pale');
      }
      .hero-banner__image-wrapper {
        &:before {
          background-color: map_get($app-colors, 'blue');
        }
      }
    }
  }

  &:after {
    @include media-breakpoint-up(lg) {
      content: '';
      position: absolute;
      right: 0;
      left: 60%;
      top: 0;
      bottom: 0;
    }
  }

  .hero-banner__text-content-wrapper {
    // padding-top: $spacer;
  }

  .hero-banner__image-wrapper {
    display: flex;
    margin-bottom: 0;
    // margin-left: -$spacer;
    // margin-right: -$spacer;
    min-width: 40%;
    padding-top: $spacer * 2;
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      display: block;
      margin-bottom: initial;
      // margin-left: initial;
      // margin-right: initial;
      padding-top: initial;
      min-height: 400px;
    }

    &:before {
      @include media-breakpoint-up(lg) {
        content: '';
        width: 50%;
        height: 60%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .hero-banner__image {
      object-fit: cover;
      height: 350px;
      min-width: 33.333333%;
      max-width: 33.333333%;

      @include media-breakpoint-up(lg) {
        position: absolute;
      }

      &.hero-banner__image--1 {
        @include media-breakpoint-up(lg) {
          left: 0;
          top: 10%;
          height: 70%;
          width: 60%;
          min-width: 0;
          max-width: none;
        }
      }
      &.hero-banner__image--2 {
        @include media-breakpoint-up(lg) {
          right: $spacer;
          top: 0;
          height: 48%;
          width: 25%;
          min-width: 0;
          max-width: none;
        }
      }
      &.hero-banner__image--3 {
        @include media-breakpoint-up(lg) {
          bottom: 0;
          height: 50%;
          right: $spacer;
          width: 50%;
          min-width: 0;
          max-width: none;
        }
      }

    }
  }
}



// ============================================================================================
// Variant Overrides (Form)
.hero-banner.hero-banner--form {
  @include media-breakpoint-up(lg) {
    padding-bottom: $spacer * 3;
    padding-top: $spacer * 3;
  }

  .hero-banner__headline {
    color: map_get($app-colors, "neutral--100");
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;

    &:after {
      display: none;
    }
  }

  .hero-banner__description {
    color: map_get($app-colors, "neutral--100");
  }

  .hero-banner__form-wrapper {
    background-color: #EDEFFD;
    padding: $spacer;
    margin: 0  auto;
    max-width: 90%;

    @include media-breakpoint-up(lg) {
      margin: 0;
      max-width: 480px;
      min-width: 480px;
      padding: $spacer * 2;
    }

    a {
      color: map_get($app-colors, "neutral--900");
    }
  }
}
