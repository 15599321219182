.order-review-msg {

    margin:0 auto;
    width:100%;
    //padding-bottom:$spacer * 3;

    @include media-breakpoint-up(lg) {
        //width:800px;
        //padding-bottom:$spacer * 10;
    }

    h1.hero-banner__headline {
        margin:3rem 0 3rem 3rem;

        @include media-breakpoint-up(lg) {
            margin:84px 0 58px 48px;
        }
    }
    
    .feedback-message {
        width:100%;
        max-width:500px;
    }

    .feedback-message .feedback-message__description {
        line-height:1.75 !important;
    }

    .feedback-message .feedback-message__description a {
        color:map_get($app-colors, 'blue') !important;
    }

    .feedback-message.feedback-message--error .feedback-message__description a {
        color:map_get($app-colors, 'red--error') !important;
    }

    .feedback-message:not(.feedback-message--error) .feedback-message__title {
        background-position: 0 15%;
        line-height:1.5
    }
}