.portal-resources  {
  @include global-component;
  margin-bottom: $spacer * 4;
  padding: 0 $spacer;

  @include media-breakpoint-up(sm) {
    padding: 0 $spacer;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.portal-resources__headline {
  @include global-component__headline--portal;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' width='18' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.75 14.063V.937A.935.935 0 0 0 16.812 0H4C1.93 0 .25 1.68.25 3.75v12.5C.25 18.32 1.93 20 4 20h12.813a.935.935 0 0 0 .937-.938v-.625a.945.945 0 0 0-.348-.73c-.164-.602-.164-2.316 0-2.918a.93.93 0 0 0 .348-.726Zm-12.5-8.83c0-.129.105-.234.234-.234h8.282c.129 0 .234.105.234.234v.782a.235.235 0 0 1-.234.234H5.484a.235.235 0 0 1-.234-.234v-.782Zm0 2.5c0-.129.105-.234.234-.234h8.282c.129 0 .234.105.234.234v.782a.235.235 0 0 1-.234.234H5.484a.235.235 0 0 1-.234-.234v-.782Zm9.898 9.766H4a1.25 1.25 0 1 1 0-2.5h11.148a14.066 14.066 0 0 0 0 2.5Z' fill='#{encode_color( map_get($app-colors, 'orange') )}'/%3E%3C/svg%3E");
}


.portal-resources__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &::after {
    content: '';
    flex: 1;
    max-width: 100%;
    min-width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 48.5%;
      min-width: 48.5%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 31.5%;
      min-width: 31.5%;
    }
  }
}

.portal-resources__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: $spacer * 2 !important;
  max-width: 100%;
  min-width: 100%;
  // transition: transform 0.5s;

  @include media-breakpoint-up(sm) {
    max-width: 48.5%;
    min-width: 48.5%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 31.5%;
    min-width: 31.5%;
  }

  &:hover {
    a.portal-resources__item-title {
      color: map_get($app-colors, "blue");
      span {
        text-decoration: underline;
      }
    }
    // transform: scale(1.03);
  }
}

.portal-resources__item-inner {
  background: map_get($app-colors, 'neutral--100');
  border-radius: $spacer;
  flex: 1;
  overflow: hidden;
  padding: $spacer;

  @include media-breakpoint-up(lg) {
    padding: $spacer * 2;
  }
}

.portal-resources__item-eyebrow {
  @include global-component__eyebrow--light;

  > * {
    color: map_get($app-colors, 'neutral--100');
  }
}


.portal-resources__item-image-wrapper {
  // display: none;
  margin: -$spacer * 2;
  margin-bottom: $spacer * 2;
  overflow: hidden;
  position: relative;
  padding-bottom: 85%;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

a.portal-resources__item-title {
  @include global-card-title;
}

.portal-resources__item-date {
  @include global-card-date;
}


.portal-resources__member-only-badge {
  @include global-member-only-badge;
  line-height:1.3;
}

.portal-resources__cta-link-wrapper {
}

a.portal-resources__cta-link {
  @include global-component__cta-link;
  color: map_get($app-colors, 'neutral--100');
  margin-top: 0;

  &:hover,
  &:focus {
    color: map_get($app-colors, "neutral--100");
  }
}
