h1,
.like-h1 {
  font-weight: 600;
  line-height: 1.3;
  font-size: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 2.625rem;
  }
}

h2,
.like-h2 {
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 1.65rem;
  }
}

h3,
.like-h3 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 1.275rem;
  }
}

h4,
.like-h4 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
  }
}

h5,
.like-h5 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3;
  text-transform: uppercase;
}

h6,
.like-h6 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3;
  text-transform: uppercase;
}
