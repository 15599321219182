.home-hero-banner {
  @include global-component;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: hidden !important;

  .btn-tertiary {
    font-size: 1rem;
    margin-top: 1rem;

    @include media-breakpoint-up(lg){
      margin-top: 2.5rem;
    }
  }
}


.home-hero-banner__content {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-hero-banner__headline {
  font-weight: 700;
  line-height: 1.3;
  font-size: 1.6rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.75rem;
  }
}

.home-hero-banner__subtitle {
  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
  }
}

.home-hero-banner__description {
  @include global-component__description;
}


.home-hero-banner__images {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 0.75fr 0.75fr 1fr 0.75fr 0.75fr;
  grid-template-rows: auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  overflow: hidden;
  align-self: center;
  align-content: end;
  margin-top: 2.25rem;
  overflow: hidden !important;
  width: 1200px;
  height: 400px;

  img {
    align-self: end;
    width: 213px;
    object-fit: cover;
  }
}

.home-hero-banner__images-stacked {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: end;
  position: relative;

  img {
    margin-top: 1rem;
  }
}

.home-hero-banner__images-stacked-bars {
  display: flex;
  flex-direction: column;
  align-self: end;
  position: relative;

  img {
    width: 199px;
  }
}

.home-hero-banner__image-center {
  img {
    width: 284px;
  }
}

.home-hero-banner__bar {
  img {
    margin-top: 0;
    max-width: 100%;
    width: auto;
  }
}

.home-hero-banner__left-bar {
  position: absolute;
  left: 1%;
  top: auto;
  right: -58px;
  bottom: 20%;
  width: 100px;
}

.home-hero-banner__right-bar {
  position: absolute;
  left: 0;
  top: -120px;
}

.home-hero-banner__right-bar-group {
  position: absolute;
  left: auto;
  top: -38%;
  right: 0%;
  bottom: auto;
  width: 80px;
}
