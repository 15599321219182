.small-top-margin {
    margin-top: $small-vertical-margin;
}

.small-bottom-margin {
    margin-bottom: $small-vertical-margin;
}

.medium-top-margin {
    margin-top: $medium-vertical-margin;
}

.medium-bottom-margin {
    margin-bottom: $medium-vertical-margin;
}

.large-top-margin {
    margin-top: $large-vertical-margin;
}

.large-bottom-margin {
    margin-bottom: $large-vertical-margin;
}