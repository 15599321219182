.cohort-directory {
  @include global-component;
  margin-bottom: $spacer * 1.5 !important;
  margin-top: $spacer * 1.5 !important;
}

.cohort-directory__headline {
  @include global-component__headline;
  font-size: 1.65rem;
  font-weight: 500;
  max-width: 900px;
}

.cohort-directory__list {
  list-style-type: none;
  margin: 0;
  padding: ($spacer * 2) 0 0 0;

  @include media-breakpoint-up(md) {
    columns: 2;
  }
}

.cohort-directory__list-item {
  border-bottom: 1px solid rgba(map-get($app-colors, "blue"), .5);
  break-inside: avoid;
  margin-bottom: ($spacer * 1.5);
  padding: 0 0 ($spacer * 0.75) 0;
  position: relative;

  &:first-child {
    &:last-child {
      border: 0;
    }
  }

  a {
    color: map-get($app-colors, "neutral--850");
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: map-get($app-colors, "blue");
      text-decoration: underline;
    }
  }
}
