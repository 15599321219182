.logo-zone {
  @include global-component;
}

.logo-zone__headline {
  font-size: .9rem;
  font-weight: 700;
  max-width: none;
  padding-top: $spacer;
  text-align: center;
  text-transform: uppercase;
}

.logo-zone__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-gap: 3rem;

  list-style-type: none;
  margin: 0;
  padding: $spacer 0 0 0;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    grid-gap: 1rem;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 1rem;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
    grid-gap: 1rem;
  }
}

.logo-zone__list-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    aspect-ratio: 1/.65;
    border: 1px solid map_get($app-colors, 'neutral--300');
  }

  @include media-breakpoint-up(lg) {
    aspect-ratio: auto;
    height: 120px;
  }

  @include media-breakpoint-up(xl) {
    height: 180px;
  }

  .external-link-icon {
    @extend .visually-hidden;
  }

  a {
    display: flex;
    min-width: 100%;
    min-height: 100%;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      padding: 1.5rem;
    }
  }

  img {
    height: auto;
    width: auto;

    /* These is-* classes are applied via javascript and are used to try and scale the logos
     * (based on aspect ratio) to make them more visually pleasing.
    */
    &.is-vertical {
      max-height: 100px;
    }

    &.is-horizontal {
      max-height: 60px;
      width: 100%;
    }

    &.is-horizontal--extreme {
      max-height: 55px;
    }
  }

  &:hover {
    border-color: map_get($app-colors, 'neutral--600');
    cursor: pointer;
  }
}


// ============================================================================
// Overides for inline variant
.logo-zone.is-inline {

  .logo-zone__list {
    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      grid-gap: 0;
      justify-content: center;
      margin: 0 auto;
    }
  }

  .logo-zone__list-item {

    @include media-breakpoint-up(lg) {
      border: 0;
      display: inline-block;
      height: initial;
    }

    a {
      @include media-breakpoint-up(lg) {
        display: inline-block;
        min-width: initial;
        min-height: initial;
        padding: 0;
      }
    }

    img {
      @include media-breakpoint-up(lg) {
        height: 25px; // Must set this so JS can calc aspect ratio reliably for svgs
        object-fit: contain;
        object-position: center;
        margin: 0 1.5rem;
        width: initial;
      }

      &.is-vertical {
        @include media-breakpoint-up(lg) {
          height: 65px;
        }
      }

      &.is-horizontal {
        @include media-breakpoint-up(lg) {
          height: 55px;
        }
      }

      &.is-horizontal--extreme {
        @include media-breakpoint-up(lg) {
          height: 35px;
          max-width: 190px;
        }
      }
    }

    &:first-child {
      img {
        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }
    }

    &:last-child {
      img {
        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }
      }
    }

    &:hover {
      @include media-breakpoint-up(lg) {
        opacity: .6;
      }
    }
  }

}


// ============================================================================
// Overides for 2 column section
.app-main__content--2col {
  .logo-zone {
    .logo-zone__headline {
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
  }
}



// ============================================================================
// Overrides pages with sidebar
body.content-has-sidebar {
  .logo-zone__list {
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      grid-gap: 1rem;
    }
  }
}
