.routing {
  @include global-component;
}

.routing__eyebrow {
  @include global-component__eyebrow;
}

.routing__headline {
  @include global-component__headline;
}

.routing__description {
  @include global-component__description;
}

.routing__stats {
  display: flex;
  margin-top: $spacer * 2.5;
  margin-bottom: $spacer * 3.75;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.routing__stat-box {
  text-align: center;
  margin-bottom: $spacer * 1.25;

  @include media-breakpoint-up(sm) {
    margin-right: $spacer * 4;
    margin-bottom: 0;
  }
}

.routing__stat {
  font-size: $spacer * 2.375;
  font-weight: 700;
  line-height: 1;
  color: map_get($app-colors, "purple");
}

.routing__stat-label {
  font-weight: bold;
}

a.routing__cta-link {
  @include global-component__cta-link;
}

.routing__cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacer * 2.25;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}

.routing__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $spacer;

  @include media-breakpoint-up(md) {
    padding-bottom: $spacer * 2.5;
  }
}

.routing__card-main {
  cursor: pointer;
  border-bottom: 1px solid map_get($app-colors, "neutral--300");
  flex: 1 1 auto;
  padding-bottom: $spacer;

  img {
    transition: all 0.2s ease-in-out;
    transform-style: preserve-3d;
    width: 100%;
    object-fit: cover;
  }

  &:hover {
    img {
      transform: scale3d(1.2, 1.2, 1);
    }
  }
}

.routing__card-date {
  @include global-card-date;
}


.routing__card-eyebrow {
  @include global-component__eyebrow--light;
}

a.routing__card-title {
  @include global-card-title;
}

.routing__card-featured-label {
  @include global-card-featured-label;
}

.routing__card-image-wrapper {
  overflow: hidden;
  margin-bottom: $spacer * 1.5;
  position: relative;
  padding-bottom: 60%;

  img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}

.routing__card-description {
  @include global-card-description;
}

.routing__card-member-only-badge {
  @include global-member-only-badge;
}

.routing__card-footer {
  color: map_get($app-colors, "darkish");
  font-size: 0.85rem;
  line-height: 1.4;
  padding-top: $spacer * .5;
}


// Adjust items when hovering over card
.routing__card {
  &:hover {
    cursor: pointer;

    a.routing__card-title {
      color: map_get($app-colors, "blue");
      span {
        text-decoration: underline;
      }
    }
  }
}
